import React, { Component } from "react";
import { Typography, Link } from "@material-ui/core";
import SbizzlAnimate from "../common/SbizzlAnimate";
import LogoPath from "../assets/img/logo-lg.png";
import axios from "axios";
import config from "../config";

class Step4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateSystemClientLoading: true,
      showAlreadyVerified: false,
      queryDidLoad: false
    };
  }

  redirectToCreatePassword = (token) => {
    window.location.href = "/createPassword?token=" + token;
  };

  initializeScreen = () => {
    if (!this.state.queryDidLoad && !this.props.signupWithGoogle) {
      let tokenParam = new URLSearchParams(this.props.location.search).get(
        "token"
      );

      axios
        .post(
          `${config.api.gatewayService}/createSystemClientWithManualEmail`,
          { tid: tokenParam },
          {
            headers: { token: tokenParam }
          }
        )
        .then((signupRes) => {
          if (signupRes.data.data.isVerified) {
            this.redirectToCreatePassword(signupRes.data.data.verifyToken);

            this.setState(
              {
                showCreateSystemClientLoading: false
              },
              () => {
                this.setState({
                  queryDidLoad: true
                });
              }
            );
          } else if (signupRes.data.data.alreadyVerified) {
            this.setState(
              {
                showCreateSystemClientLoading: false,
                showAlreadyVerified: true
              },
              () => {
                this.setState({
                  queryDidLoad: true
                });
              }
            );
          }
        })
        .catch((err) => {
          console.log("Error in create system client : ", err);
        });
    }
  };

  render() {
    this.initializeScreen();

    return !this.props.signupWithGoogle ? (
      <div className="ViewContainer">
        <SbizzlAnimate animation="transition.slideRightIn" delay={200}>
          <div className="header">
            <img src={LogoPath} alt="logo" />
          </div>
        </SbizzlAnimate>

        {this.state.showCreateSystemClientLoading && (
          <>
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                backgroundColor: "rgba(0, 0, 0, 0.34)",
                zIndex: "99"
              }}
            />

            <div
              className="spinner-wrapper"
              style={{
                justifyContent: "center",
                alignSelf: "center",
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                margin: "auto",
                zIndex: "9999",
                color: "#009fe2"
              }}
            >
              <div className="spinner">
                <div className="inner">
                  <div className="gap" />
                  <div className="left">
                    <div className="half-circle" />
                  </div>
                  <div className="right">
                    <div className="half-circle" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="ViewComponent">
          <SbizzlAnimate animation="transition.slideRightIn" delay={250}>
            <div className="formWraper">
              <div className="forTextStyle">
                {!this.state.showAlreadyVerified ? (
                  <Typography variant="h3" className="secondaryColor">
                    Hold tight! We are creating your account and ready to use
                    soon.
                  </Typography>
                ) : (
                  <Typography variant="h3" className="secondaryColor">
                    You have already verified your email address. Please sign in
                    to{" "}
                    <Link
                      href="https://app.sbizzl.com/login"
                      target="_blank"
                      style={{ color: "#02a8ee" }}
                    >
                      app.sBizzl.com
                    </Link>
                    .
                  </Typography>
                )}
              </div>
            </div>
          </SbizzlAnimate>
          <SbizzlAnimate animation="transition.slideRightIn" delay={400}>
            <div className="imagBlockContainer">
              <div className="imagBlock">
                <svg
                  id="undraw_secure_login_pdn4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="212"
                  height="148"
                  viewBox="0 0 212 148"
                >
                  <ellipse
                    id="Ellipse_57"
                    data-name="Ellipse 57"
                    cx="42.428"
                    cy="3.222"
                    rx="42.428"
                    ry="3.222"
                    transform="translate(0 141.555)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_195"
                    data-name="Path 195"
                    d="M324.272,235.025c7.384-3.141,16.58-1.151,25.562.23.084-1.672,1.1-3.708.035-4.875a3.545,3.545,0,0,1-.395-4.4c1.984-3.831-.858-7.907-3.728-11.3a6.356,6.356,0,0,0-5.3-2.227l-5.316.38a6.367,6.367,0,0,0-5.653,4.551h0c-1.269,1.726-1.949,3.448-1.521,5.161-1.9,1.3-2.221,2.868-1.366,4.636a2.665,2.665,0,0,1-.033,3.251,15.008,15.008,0,0,0-2.232,4.432Z"
                    transform="translate(-292.005 -202.151)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_196"
                    data-name="Path 196"
                    d="M554.035,624.4H403.546a5.046,5.046,0,0,1-5.046-5.046h0a680.185,680.185,0,0,1,160.581,0h0a5.046,5.046,0,0,1-5.046,5.046Z"
                    transform="translate(-347.081 -496.477)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M559.081,341.529l-160.581-.3,18.611-31.315.089-.148V240.756a6.378,6.378,0,0,1,6.379-6.379H533.112a6.378,6.378,0,0,1,6.379,6.379v69.488Z"
                    transform="translate(-347.081 -218.2)"
                    fill="#3f3d56"
                  />
                  <path
                    id="Path_198"
                    data-name="Path 198"
                    d="M485.69,250.957a2.08,2.08,0,0,0-2.078,2.078v62.926a2.08,2.08,0,0,0,2.078,2.078H596.405a2.08,2.08,0,0,0,2.078-2.078V253.034a2.08,2.08,0,0,0-2.078-2.078Z"
                    transform="translate(-409.338 -230.327)"
                    fill="#fff"
                  />
                  <path
                    id="Path_199"
                    data-name="Path 199"
                    d="M475.031,531.718a.893.893,0,0,0-.809.518L468.488,544.7a.89.89,0,0,0,.809,1.263H589.891a.89.89,0,0,0,.8-1.289l-6.233-12.467a.886.886,0,0,0-.8-.492Z"
                    transform="translate(-398.215 -435.808)"
                    fill="#2f2e41"
                  />
                  <circle
                    id="Ellipse_58"
                    data-name="Ellipse 58"
                    cx="1.336"
                    cy="1.336"
                    r="1.336"
                    transform="translate(130.285 16.919)"
                    fill="#fff"
                  />
                  <path
                    id="Path_200"
                    data-name="Path 200"
                    d="M645.356,593.619a.892.892,0,0,0-.86.659l-1.438,5.343a.89.89,0,0,0,.86,1.122h27.356a.89.89,0,0,0,.841-1.182l-1.849-5.343a.891.891,0,0,0-.841-.6Z"
                    transform="translate(-525.607 -481.1)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_201"
                    data-name="Path 201"
                    d="M386.686,337.135v1.187H264.306l.092-.148v-1.039Z"
                    transform="translate(-194.276 -246.701)"
                    fill="#2f2e41"
                  />
                  <circle
                    id="Ellipse_59"
                    data-name="Ellipse 59"
                    cx="20.778"
                    cy="20.778"
                    r="20.778"
                    transform="translate(168.219 0)"
                    fill="#02a8ee"
                  />
                  <path
                    id="Path_202"
                    data-name="Path 202"
                    d="M892.76,250.456H875.545a1.189,1.189,0,0,1-1.187-1.187V238.88a1.189,1.189,0,0,1,1.187-1.187H892.76a1.189,1.189,0,0,1,1.187,1.187v10.389a1.188,1.188,0,0,1-1.187,1.187ZM875.545,238.88v10.389h17.217V238.88Z"
                    transform="translate(-695.156 -220.625)"
                    fill="#fff"
                  />
                  <path
                    id="Path_203"
                    data-name="Path 203"
                    d="M901.624,213.006H890.938v-4.749c0-3.44,2.247-5.936,5.343-5.936s5.343,2.5,5.343,5.936Zm-9.5-1.187h8.311v-3.562c0-2.8-1.709-4.749-4.156-4.749s-4.156,1.953-4.156,4.749Z"
                    transform="translate(-707.285 -194.752)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellipse_60"
                    data-name="Ellipse 60"
                    cx="1.187"
                    cy="1.187"
                    r="1.187"
                    transform="translate(187.809 21.965)"
                    fill="#fff"
                  />
                  <path
                    id="Path_204"
                    data-name="Path 204"
                    d="M619.428,354.835H533.864a1.586,1.586,0,0,1-1.585-1.585V332.026a1.586,1.586,0,0,1,1.585-1.585h85.564a1.586,1.586,0,0,1,1.585,1.585v21.225A1.586,1.586,0,0,1,619.428,354.835Zm-85.564-23.76a.952.952,0,0,0-.951.951v21.225a.952.952,0,0,0,.951.951h85.564a.952.952,0,0,0,.951-.951V332.026a.952.952,0,0,0-.951-.951Z"
                    transform="translate(-444.663 -288.521)"
                    fill="#e6e6e6"
                  />
                  <circle
                    id="Ellipse_61"
                    data-name="Ellipse 61"
                    cx="6.454"
                    cy="6.454"
                    r="6.454"
                    transform="translate(96.255 46.847)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_205"
                    data-name="Path 205"
                    d="M640.273,356.8a1.076,1.076,0,1,0,0,2.151h50.714a1.076,1.076,0,0,0,0-2.151Z"
                    transform="translate(-522.889 -307.805)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_206"
                    data-name="Path 206"
                    d="M640.273,380.842a1.076,1.076,0,1,0,0,2.152H662.1a1.076,1.076,0,0,0,0-2.152Z"
                    transform="translate(-522.784 -325.394)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_207"
                    data-name="Path 207"
                    d="M423.391,357.342l-15.817,8.409-.2-7.008a46.843,46.843,0,0,0,14.616-4.805l1.654-4.087a2.771,2.771,0,0,1,4.709-.719h0a2.771,2.771,0,0,1-.244,3.778Z"
                    transform="translate(-352.897 -301.456)"
                    fill="#ffb8b8"
                  />
                  <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M322.8,534.38h0a3.108,3.108,0,0,1,.395-2.514l3.475-5.332a6.072,6.072,0,0,1,7.877-2.078h0c-1.46,2.486-1.257,4.666.5,6.561a31.584,31.584,0,0,0-7.5,5.114,3.108,3.108,0,0,1-4.749-1.75Z"
                    transform="translate(-290.835 -429.995)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M390.3,473.672h0a5.962,5.962,0,0,1-5.067,2.895l-23.083.175-1-5.806,10.211-3-8.609-6.607,9.41-11.012,17.092,15.93a5.962,5.962,0,0,1,1.048,7.429Z"
                    transform="translate(-319.032 -376.246)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_210"
                    data-name="Path 210"
                    d="M323.4,469.75h-5.406c-4.854-33.27-9.794-66.634,4.805-79.086l17.219,2.8-2.2,14.616-9.61,11.012Z"
                    transform="translate(-283.67 -332.676)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_211"
                    data-name="Path 211"
                    d="M338.8,687.19h0a3.108,3.108,0,0,1-2.514-.394l-5.87-.789a6.072,6.072,0,0,1-2.079-7.877h0c2.486,1.46,4.666,1.256,6.561-.5,1.339,2.7,3.545,2.538,5.652,4.816a3.108,3.108,0,0,1-1.75,4.749Z"
                    transform="translate(-294.474 -542.579)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_212"
                    data-name="Path 212"
                    d="M371.9,270.27l-10.011-2.4c1.663-3.4,1.8-7.19,1-11.212l6.807-.2A52.263,52.263,0,0,0,371.9,270.27Z"
                    transform="translate(-319.557 -234.349)"
                    fill="#ffb8b8"
                  />
                  <path
                    id="Path_213"
                    data-name="Path 213"
                    d="M358.36,325.322c-7.288,4.966-12.437.17-16.366-7.23.546-4.528-.338-9.946-1.975-15.834a10.778,10.778,0,0,1,6.581-13h0l8.609,3.6c7.31,5.959,8.749,12.413,6.007,19.221Z"
                    transform="translate(-303.246 -258.39)"
                    fill="#02a8ee"
                  />
                  <path
                    id="Path_214"
                    data-name="Path 214"
                    d="M282.383,304.181l-8.009,4.2,14.215,8.409,1.978,4.879a2.588,2.588,0,0,1-1.555,3.419h0a2.588,2.588,0,0,1-3.427-2.294l-.2-3.4-18.068-5.962a4.225,4.225,0,0,1-2.651-2.581h0a4.225,4.225,0,0,1,1.585-4.915l14.529-9.967Z"
                    transform="translate(-248.059 -263.298)"
                    fill="#ffb8b8"
                  />
                  <path
                    id="Path_215"
                    data-name="Path 215"
                    d="M331.695,299.224c-3.34-1.464-6.377.126-10.211,1.4l-.6-10.612c3.807-2.029,7.436-2.576,10.812-1Z"
                    transform="translate(-289.362 -257.532)"
                    fill="#02a8ee"
                  />
                  <circle
                    id="Ellipse_62"
                    data-name="Ellipse 62"
                    cx="6.392"
                    cy="6.392"
                    r="6.392"
                    transform="translate(39.748 14.579)"
                    fill="#ffb8b8"
                  />
                  <path
                    id="Path_216"
                    data-name="Path 216"
                    d="M365.732,233.6c-6.328.62-11.15-.415-14.232-3.362v-2.373h13.7Z"
                    transform="translate(-311.946 -213.438)"
                    fill="#2f2e41"
                  />
                </svg>
              </div>
            </div>
          </SbizzlAnimate>
        </div>
      </div>
    ) : (
      <>
        {this.state.showCreateSystemClientLoading && (
          <>
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                backgroundColor: "rgba(0, 0, 0, 0.34)",
                zIndex: "99"
              }}
            />

            <div
              className="spinner-wrapper"
              style={{
                justifyContent: "center",
                alignSelf: "center",
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                margin: "auto",
                zIndex: "9999",
                color: "#009fe2"
              }}
            >
              <div className="spinner">
                <div className="inner">
                  <div className="gap" />
                  <div className="left">
                    <div className="half-circle" />
                  </div>
                  <div className="right">
                    <div className="half-circle" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <SbizzlAnimate animation="transition.fadeIn" delay={200}>
          <>
            <span className="bgProgress" />
            <div
              className="stepper-progress-bar"
              style={{
                width: "66.66%"
              }}
            />
          </>
        </SbizzlAnimate>

        <div className="ViewComponent">
          <SbizzlAnimate animation="transition.slideRightIn" delay={250}>
            <div className="formWraper">
              <div className="forTextStyle">
                {!this.state.showAlreadyVerified ? (
                  <Typography variant="h3" className="secondaryColor">
                    Hold tight! We are creating your account and ready to use
                    soon.
                  </Typography>
                ) : (
                  <Typography variant="h3" className="secondaryColor">
                    You have already verified your email address. Please sign in
                    to{" "}
                    <Link
                      href="https://app.sbizzl.com/login"
                      target="_blank"
                      style={{ color: "#02a8ee" }}
                    >
                      app.sBizzl.com
                    </Link>
                    .
                  </Typography>
                )}
              </div>
            </div>
          </SbizzlAnimate>
          <SbizzlAnimate animation="transition.slideRightIn" delay={400}>
            <div className="imagBlockContainer">
              <div className="imagBlock">
                <svg
                  id="undraw_secure_login_pdn4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="212"
                  height="148"
                  viewBox="0 0 212 148"
                >
                  <ellipse
                    id="Ellipse_57"
                    data-name="Ellipse 57"
                    cx="42.428"
                    cy="3.222"
                    rx="42.428"
                    ry="3.222"
                    transform="translate(0 141.555)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_195"
                    data-name="Path 195"
                    d="M324.272,235.025c7.384-3.141,16.58-1.151,25.562.23.084-1.672,1.1-3.708.035-4.875a3.545,3.545,0,0,1-.395-4.4c1.984-3.831-.858-7.907-3.728-11.3a6.356,6.356,0,0,0-5.3-2.227l-5.316.38a6.367,6.367,0,0,0-5.653,4.551h0c-1.269,1.726-1.949,3.448-1.521,5.161-1.9,1.3-2.221,2.868-1.366,4.636a2.665,2.665,0,0,1-.033,3.251,15.008,15.008,0,0,0-2.232,4.432Z"
                    transform="translate(-292.005 -202.151)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_196"
                    data-name="Path 196"
                    d="M554.035,624.4H403.546a5.046,5.046,0,0,1-5.046-5.046h0a680.185,680.185,0,0,1,160.581,0h0a5.046,5.046,0,0,1-5.046,5.046Z"
                    transform="translate(-347.081 -496.477)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M559.081,341.529l-160.581-.3,18.611-31.315.089-.148V240.756a6.378,6.378,0,0,1,6.379-6.379H533.112a6.378,6.378,0,0,1,6.379,6.379v69.488Z"
                    transform="translate(-347.081 -218.2)"
                    fill="#3f3d56"
                  />
                  <path
                    id="Path_198"
                    data-name="Path 198"
                    d="M485.69,250.957a2.08,2.08,0,0,0-2.078,2.078v62.926a2.08,2.08,0,0,0,2.078,2.078H596.405a2.08,2.08,0,0,0,2.078-2.078V253.034a2.08,2.08,0,0,0-2.078-2.078Z"
                    transform="translate(-409.338 -230.327)"
                    fill="#fff"
                  />
                  <path
                    id="Path_199"
                    data-name="Path 199"
                    d="M475.031,531.718a.893.893,0,0,0-.809.518L468.488,544.7a.89.89,0,0,0,.809,1.263H589.891a.89.89,0,0,0,.8-1.289l-6.233-12.467a.886.886,0,0,0-.8-.492Z"
                    transform="translate(-398.215 -435.808)"
                    fill="#2f2e41"
                  />
                  <circle
                    id="Ellipse_58"
                    data-name="Ellipse 58"
                    cx="1.336"
                    cy="1.336"
                    r="1.336"
                    transform="translate(130.285 16.919)"
                    fill="#fff"
                  />
                  <path
                    id="Path_200"
                    data-name="Path 200"
                    d="M645.356,593.619a.892.892,0,0,0-.86.659l-1.438,5.343a.89.89,0,0,0,.86,1.122h27.356a.89.89,0,0,0,.841-1.182l-1.849-5.343a.891.891,0,0,0-.841-.6Z"
                    transform="translate(-525.607 -481.1)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_201"
                    data-name="Path 201"
                    d="M386.686,337.135v1.187H264.306l.092-.148v-1.039Z"
                    transform="translate(-194.276 -246.701)"
                    fill="#2f2e41"
                  />
                  <circle
                    id="Ellipse_59"
                    data-name="Ellipse 59"
                    cx="20.778"
                    cy="20.778"
                    r="20.778"
                    transform="translate(168.219 0)"
                    fill="#02a8ee"
                  />
                  <path
                    id="Path_202"
                    data-name="Path 202"
                    d="M892.76,250.456H875.545a1.189,1.189,0,0,1-1.187-1.187V238.88a1.189,1.189,0,0,1,1.187-1.187H892.76a1.189,1.189,0,0,1,1.187,1.187v10.389a1.188,1.188,0,0,1-1.187,1.187ZM875.545,238.88v10.389h17.217V238.88Z"
                    transform="translate(-695.156 -220.625)"
                    fill="#fff"
                  />
                  <path
                    id="Path_203"
                    data-name="Path 203"
                    d="M901.624,213.006H890.938v-4.749c0-3.44,2.247-5.936,5.343-5.936s5.343,2.5,5.343,5.936Zm-9.5-1.187h8.311v-3.562c0-2.8-1.709-4.749-4.156-4.749s-4.156,1.953-4.156,4.749Z"
                    transform="translate(-707.285 -194.752)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellipse_60"
                    data-name="Ellipse 60"
                    cx="1.187"
                    cy="1.187"
                    r="1.187"
                    transform="translate(187.809 21.965)"
                    fill="#fff"
                  />
                  <path
                    id="Path_204"
                    data-name="Path 204"
                    d="M619.428,354.835H533.864a1.586,1.586,0,0,1-1.585-1.585V332.026a1.586,1.586,0,0,1,1.585-1.585h85.564a1.586,1.586,0,0,1,1.585,1.585v21.225A1.586,1.586,0,0,1,619.428,354.835Zm-85.564-23.76a.952.952,0,0,0-.951.951v21.225a.952.952,0,0,0,.951.951h85.564a.952.952,0,0,0,.951-.951V332.026a.952.952,0,0,0-.951-.951Z"
                    transform="translate(-444.663 -288.521)"
                    fill="#e6e6e6"
                  />
                  <circle
                    id="Ellipse_61"
                    data-name="Ellipse 61"
                    cx="6.454"
                    cy="6.454"
                    r="6.454"
                    transform="translate(96.255 46.847)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_205"
                    data-name="Path 205"
                    d="M640.273,356.8a1.076,1.076,0,1,0,0,2.151h50.714a1.076,1.076,0,0,0,0-2.151Z"
                    transform="translate(-522.889 -307.805)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_206"
                    data-name="Path 206"
                    d="M640.273,380.842a1.076,1.076,0,1,0,0,2.152H662.1a1.076,1.076,0,0,0,0-2.152Z"
                    transform="translate(-522.784 -325.394)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_207"
                    data-name="Path 207"
                    d="M423.391,357.342l-15.817,8.409-.2-7.008a46.843,46.843,0,0,0,14.616-4.805l1.654-4.087a2.771,2.771,0,0,1,4.709-.719h0a2.771,2.771,0,0,1-.244,3.778Z"
                    transform="translate(-352.897 -301.456)"
                    fill="#ffb8b8"
                  />
                  <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M322.8,534.38h0a3.108,3.108,0,0,1,.395-2.514l3.475-5.332a6.072,6.072,0,0,1,7.877-2.078h0c-1.46,2.486-1.257,4.666.5,6.561a31.584,31.584,0,0,0-7.5,5.114,3.108,3.108,0,0,1-4.749-1.75Z"
                    transform="translate(-290.835 -429.995)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M390.3,473.672h0a5.962,5.962,0,0,1-5.067,2.895l-23.083.175-1-5.806,10.211-3-8.609-6.607,9.41-11.012,17.092,15.93a5.962,5.962,0,0,1,1.048,7.429Z"
                    transform="translate(-319.032 -376.246)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_210"
                    data-name="Path 210"
                    d="M323.4,469.75h-5.406c-4.854-33.27-9.794-66.634,4.805-79.086l17.219,2.8-2.2,14.616-9.61,11.012Z"
                    transform="translate(-283.67 -332.676)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_211"
                    data-name="Path 211"
                    d="M338.8,687.19h0a3.108,3.108,0,0,1-2.514-.394l-5.87-.789a6.072,6.072,0,0,1-2.079-7.877h0c2.486,1.46,4.666,1.256,6.561-.5,1.339,2.7,3.545,2.538,5.652,4.816a3.108,3.108,0,0,1-1.75,4.749Z"
                    transform="translate(-294.474 -542.579)"
                    fill="#2f2e41"
                  />
                  <path
                    id="Path_212"
                    data-name="Path 212"
                    d="M371.9,270.27l-10.011-2.4c1.663-3.4,1.8-7.19,1-11.212l6.807-.2A52.263,52.263,0,0,0,371.9,270.27Z"
                    transform="translate(-319.557 -234.349)"
                    fill="#ffb8b8"
                  />
                  <path
                    id="Path_213"
                    data-name="Path 213"
                    d="M358.36,325.322c-7.288,4.966-12.437.17-16.366-7.23.546-4.528-.338-9.946-1.975-15.834a10.778,10.778,0,0,1,6.581-13h0l8.609,3.6c7.31,5.959,8.749,12.413,6.007,19.221Z"
                    transform="translate(-303.246 -258.39)"
                    fill="#02a8ee"
                  />
                  <path
                    id="Path_214"
                    data-name="Path 214"
                    d="M282.383,304.181l-8.009,4.2,14.215,8.409,1.978,4.879a2.588,2.588,0,0,1-1.555,3.419h0a2.588,2.588,0,0,1-3.427-2.294l-.2-3.4-18.068-5.962a4.225,4.225,0,0,1-2.651-2.581h0a4.225,4.225,0,0,1,1.585-4.915l14.529-9.967Z"
                    transform="translate(-248.059 -263.298)"
                    fill="#ffb8b8"
                  />
                  <path
                    id="Path_215"
                    data-name="Path 215"
                    d="M331.695,299.224c-3.34-1.464-6.377.126-10.211,1.4l-.6-10.612c3.807-2.029,7.436-2.576,10.812-1Z"
                    transform="translate(-289.362 -257.532)"
                    fill="#02a8ee"
                  />
                  <circle
                    id="Ellipse_62"
                    data-name="Ellipse 62"
                    cx="6.392"
                    cy="6.392"
                    r="6.392"
                    transform="translate(39.748 14.579)"
                    fill="#ffb8b8"
                  />
                  <path
                    id="Path_216"
                    data-name="Path 216"
                    d="M365.732,233.6c-6.328.62-11.15-.415-14.232-3.362v-2.373h13.7Z"
                    transform="translate(-311.946 -213.438)"
                    fill="#2f2e41"
                  />
                </svg>
              </div>
            </div>
          </SbizzlAnimate>
        </div>
      </>
    );
  }
}

export default Step4;
