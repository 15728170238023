module.exports = {
  api: {
    port: 8080,
    bodyLimit: "100kb",
    corsHeaders: ["Link"],
    graphqlService: "http://localhost:5000",
    // iamService: "http://localhost:5001",
    // saService: "http://localhost:8080",
    // hrService: "http://localhost:5002",
    gatewayService: "http://localhost:5012"
  },
  urls: {
    signin: "https://sbizzl.com/",
  }
};
