import React from "react";
import TextField from "@material-ui/core/TextField";
/* global google */
import Search from "@material-ui/icons/Search";

class GooglePlacesAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.current,
      { types: ["address"], componentRestrictions: { country: ["us"] } }
    );

    this.autocomplete.setFields(["address_component"]);

    this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
  }

  handlePlaceChanged = () => {
    const place = this.autocomplete.getPlace();
    // this.autocompleteInput.current.value = "test",
    this.props.onChangeAddress(place);
    this.autocompleteInput.current.value = this.props.value;
  };

  render() {
    return (
      <form noValidate style={{ width: "428px" }}>
        <input
          style={{ width: "100%" }}
          className="customInputAuto w-full change"
          onFocus={this.props.onFocus}
          onBlur={(e) => {
            if (
              !e.target.value ||
              e.target.value === null ||
              e.target.value === ""
            ) {
              this.props.onBlur(e);
            }
          }}
          onChange={(e) => {
            this.props.onChange(e.target.value);
          }}
          ref={this.autocompleteInput}
          id={this.props.parentId}
          placeholder={this.props.placeholder}
          type="text"
        />
      </form>
    );
  }
}

class MaterialUIGooglePlaces extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <TextField
          id={this.props.id || "googleAddressSearch"}
          label={this.props.placeholder}
          type="text"
          className="w-full customTextField change"
          style={this.props.style}
          fullWidth
          InputProps={{
            inputComponent: GooglePlacesAutocomplete,
            inputProps: {
              onChangeAddress: (data) => {
                this.props.onSelect(data);
              },
              onChange: (value) => {
                this.props.onChange(value);
              },
              innerRef: (r) => {
                this.props.innerRef && this.props.innerRef(r);
              },
              parentId: this.props.id || "googleAddressSearch",
              placeholder: this.props.placeholder,
              value: this.props.addrLineValue
            }
          }}
          error={this.props.error}
          helperText={this.props.helperText}
        />
        <div className="searchIcon">
          <Search />
        </div>
      </>
    );
  }
}

// export default GooglePlacesAutocomplete;
export default MaterialUIGooglePlaces;
