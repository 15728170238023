import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import config from "../config";
import axios from "axios";
import {
  Input,
  Button,
  Typography,
  Grid,
  Link,
  FormHelperText,
  Divider
} from "@material-ui/core";
import SbizzlAnimate from "../common/SbizzlAnimate";

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgree: true,
      fName: "",
      lName: "",
      email: "",
      dataSet: true,
      unverifiedEmailExists: false
    };
  }

  signInWithGoogle = async () => {
    await this.props.signOut();
    this.props.signInWithGoogle();
    this.setState({
      dataSet: false
    });
  };

  initializeScreen = () => {
    if (!this.state.dataSet && this.props.user && this.props.user.email) {
      let fullName = this.props.user.displayName.split(" ");
      this.setState({
        dataSet: true
      });
      let stateVars = {
        isAgree: true,
        fName: fullName[0],
        lName: fullName[fullName.length - 1],
        email: this.props.user.email,
        emailVerified: this.props.user.emailVerified,
        signupWithGoogle: true
      };
      this.onSaveInfo(stateVars);
    }
  };

  onSaveInfo = async (values) => {
    this.props.saveInfo({
      ...values,
      unverifiedEmailExists: this.state.unverifiedEmailExists
    });
    await this.props.goNextStep();
  };

  checkUnverifiedEmail = (values) => {
    if (values.email?.trim()) {
      axios
        .get(
          `${
            config.api.gatewayService
          }/signupRequest/unverifiedEmail/${values.email.trim()}`,
          {}
        )
        .then((res) => {
          this.setState(
            {
              unverifiedEmailExists: false
            },
            () => {
              this.onSaveInfo(values);
            }
          );
        })
        .catch((err) => {
          if (err.response.status === 406) {
            this.setState(
              {
                unverifiedEmailExists: true
              },
              () => {
                this.onSaveInfo(values);
              }
            );
          } else {
            this.setState(
              {
                unverifiedEmailExists: false
              },
              () => {
                this.onSaveInfo(values);
              }
            );
          }
        });
    }
  };

  render() {
    this.initializeScreen();
    const validateSchema = yup.object().shape({
      fName: yup
        .string()
        .nullable()
        .trim()
        .min(1, "Please enter at least 1 character")
        .max(50, "First name accept up to 50 characters")
        .required("Please enter first name"),
      lName: yup
        .string()
        .nullable()
        .trim()
        .max(50, "Last name accept up to 50 characters"),
      email: yup
        .string()
        .nullable()
        .trim()
        .min(1, "Please enter at least 1 character")
        .max(250, "Email accept up to 250 characters")
        .email("Please enter valid email")
        .required("Please enter email")
    });
    return (
      <Formik
        initialValues={this.props.info}
        enableReinitialize={true}
        validationSchema={validateSchema}
        onSubmit={(values) => {
          this.checkUnverifiedEmail(values);
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
          } = props;
          return (
            <form
              className="ViewComponent"
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <SbizzlAnimate animation="transition.slideRightIn" delay={250}>
                <div className="formWraper">
                  <div className="text-center">
                    <Typography variant="h3" className="secondaryColor">
                      {" "}
                      Create your account{" "}
                    </Typography>
                  </div>
                  <Button
                    type="button"
                    onClick={this.signInWithGoogle}
                    variant="contained"
                    color="primary"
                    size="large"
                    className="signupButton"
                  >
                    <span className="btnImg" />
                    Sign up with Google
                  </Button>
                  <div className="formdivider">
                    <span className="classOr">Or</span>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <div className="inputControl">
                        <Input
                          id="fName"
                          error={errors.fName && touched.fName}
                          helperText={
                            errors.fName && touched.fName && errors.fName
                          }
                          value={values.fName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          aria-describedby="fName"
                          placeholder="First name"
                          name="fName"
                          type="text"
                          className="customInput"
                          autoComplete="chrome-off"
                        />
                        {errors.fName && touched.fName && (
                          <FormHelperText className="errorText">
                            {errors.fName}
                          </FormHelperText>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="inputControl">
                        <Input
                          id="lName"
                          value={values.lName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          aria-describedby="lName"
                          placeholder="Last name"
                          name="lName"
                          type="text"
                          className="customInput"
                          autoComplete="chrome-off"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="inputControl">
                      <Input
                        id="email"
                        error={errors.email && touched.email}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-describedby="email"
                        placeholder="Email address"
                        name="email"
                        type="email"
                        className="customInput"
                        autoComplete="chrome-off"
                      />
                      {errors.email && touched.email && (
                        <FormHelperText className="errorText">
                          {errors.email}
                        </FormHelperText>
                      )}
                    </div>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="nextButton mt-0"
                    onClick={handleSubmit}
                    size="large"
                    disabled={!values.email || !values.fName}
                  >
                    Next
                  </Button>
                  <Typography variant="p" className="policyTextStyle">
                    By creating a sBizzl account, you're agreeing to accept the{" "}
                    <Link
                      href="https://legal.sbizzl.com/terms-and-conditions/"
                      target="_blank"
                    >
                      sBizzl Customer Terms of Service.
                    </Link>
                  </Typography>
                  <Divider />
                  <Typography variant="p" className="policyTextStyle">
                    We're committed to your privacy. sBizzl uses the information
                    you provide to us to contact you about our relevant content,
                    products, and services. You may unsubscribe from these
                    communications at any time. For more information, check out
                    our{" "}
                    <Link
                      href="https://legal.sbizzl.com/privacy-policy/"
                      target="_blank"
                    >
                      Privacy policy.
                    </Link>
                  </Typography>
                </div>
              </SbizzlAnimate>
              <SbizzlAnimate animation="transition.slideRightIn" delay={400}>
                <div className="imagBlockContainer">
                  <div className="imagBlock">
                    <svg
                      id="Group_6526"
                      data-name="Group 6526"
                      xmlns="http://www.w3.org/2000/svg"
                      width="290"
                      height="260"
                      viewBox="0 0 290 260"
                    >
                      <path
                        id="blob"
                        d="M209.38,18.142c19.414,49.039-.99,117.619-46.356,147.306S47.531,185.739-3.183,150.792C-53.7,116.032-85,55.532-69.15,11.378-53.5-32.964,9.1-60.96,70.115-60.4,130.932-59.832,190.164-30.709,209.38,18.142Z"
                        transform="translate(73.289 69.123)"
                        fill="#02a8ee"
                        opacity="0.4"
                      />
                      <path
                        id="blob-2"
                        data-name="blob"
                        d="M-66.113,23.433c-19.006,52.343.97,125.543,45.381,157.229s113.065,21.659,162.713-15.643c49.454-37.1,80.1-101.677,64.581-148.806C191.241-31.116,129.957-61,70.224-60.4,10.686-59.794-47.3-28.709-66.113,23.433Z"
                        transform="translate(75.905 60.404)"
                        fill="#02a8ee"
                        opacity="0.1"
                      />
                      <g
                        id="undraw_authentication_re_svpt"
                        transform="translate(58.845 36.842)"
                      >
                        <path
                          id="Path_133"
                          data-name="Path 133"
                          d="M493.958,206.754H333.773a5.39,5.39,0,0,1-5.383-5.383V138.582a5.39,5.39,0,0,1,5.383-5.383H493.958a5.39,5.39,0,0,1,5.383,5.383V201.37a5.39,5.39,0,0,1-5.383,5.383Z"
                          transform="translate(-328.39 -133.199)"
                          fill="#f2f2f2"
                        />
                        <path
                          id="Path_134"
                          data-name="Path 134"
                          d="M464.069,210.794h-97.4a27.407,27.407,0,0,1-27.376-27.376V171.486a27.407,27.407,0,0,1,27.376-27.376H476a27.407,27.407,0,0,1,27.376,27.376A39.353,39.353,0,0,1,464.069,210.794Z"
                          transform="translate(-335.86 -140.673)"
                          fill="#fff"
                        />
                        <path
                          id="Path_135"
                          data-name="Path 135"
                          d="M512.629,195.136H448.247a1.388,1.388,0,0,1,0-2.777h64.382a1.388,1.388,0,0,1,0,2.777Z"
                          transform="translate(-409.545 -173.725)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="Path_136"
                          data-name="Path 136"
                          d="M545.22,242.167H448.247a1.388,1.388,0,0,1,0-2.777H545.22a1.388,1.388,0,0,1,0,2.777Z"
                          transform="translate(-409.545 -205.943)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="Path_137"
                          data-name="Path 137"
                          d="M545.22,289.2H448.247a1.388,1.388,0,1,1,0-2.777H545.22a1.388,1.388,0,0,1,0,2.777Z"
                          transform="translate(-409.545 -236.722)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="Path_138"
                          data-name="Path 138"
                          d="M545.22,289.2H448.247a1.388,1.388,0,1,1,0-2.777H545.22a1.388,1.388,0,0,1,0,2.777Z"
                          transform="translate(-409.545 -236.722)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="Path_139"
                          data-name="Path 139"
                          d="M634.1,247.072a2.863,2.863,0,0,0,3.467,2.692l5.295,8.686,2.253-4.782-5.374-7.515a2.878,2.878,0,0,0-5.642.919Z"
                          transform="translate(-537.806 -208.042)"
                          fill="#ffb6b6"
                        />
                        <path
                          id="Path_140"
                          data-name="Path 140"
                          d="M684.64,286.363l-18.247-9.9-5.756-5.4s-2.5-.67-1.581-1.483-1.7-1.6-1.7-1.6l-3.09-2.9-4.391,3.107,1.271,1.97s-.087,3.261,1.7,2.633,1.34,2.077,1.34,2.077L664.844,291.4Z"
                          transform="translate(-548.615 -222.138)"
                          fill="#3f3d56"
                        />
                        <circle
                          id="Ellipse_54"
                          data-name="Ellipse 54"
                          cx="17.823"
                          cy="17.823"
                          r="17.823"
                          transform="translate(64.339 17.847)"
                          fill="#02a8ee"
                        />
                        <path
                          id="Path_141"
                          data-name="Path 141"
                          d="M420.148,580.513h-3.706l-1.764-14.294h5.469Z"
                          transform="translate(-284.067 -382.009)"
                          fill="#ffb6b6"
                        />
                        <path
                          id="Path_142"
                          data-name="Path 142"
                          d="M738.414,744.932h-2.658l-.474-2.509-1.215,2.509h-7.048a1.584,1.584,0,0,1-.9-2.888l5.629-3.888V735.62l5.921.353Z"
                          transform="translate(-600.377 -540.008)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_143"
                          data-name="Path 143"
                          d="M326.046,580.513H322.34l-1.763-14.294h5.469Z"
                          transform="translate(-219.605 -382.009)"
                          fill="#ffb6b6"
                        />
                        <path
                          id="Path_144"
                          data-name="Path 144"
                          d="M644.312,744.932h-2.658l-.474-2.509-1.215,2.509h-7.048a1.584,1.584,0,0,1-.9-2.888l5.629-3.888V735.62l5.921.353Z"
                          transform="translate(-535.914 -540.008)"
                          fill="#2f2e41"
                        />
                        <rect
                          id="Rectangle_1211"
                          data-name="Rectangle 1211"
                          width="18.704"
                          height="34.294"
                          transform="translate(113.445 87.033)"
                          fill="#ffb6b6"
                        />
                        <path
                          id="Path_145"
                          data-name="Path 145"
                          d="M634.861,434.927,631.7,438.21l-5.468,10.1-13.776,28.77-2.914,6.085,2.353,10.756s.381,7.578.381,8.818a5.007,5.007,0,0,0,2.407,3.925l1.766,8.072,17.122-.631s-.6-8.9-4.183-11.142-5.234-13.941-5.234-13.941l-1.949-5.19,18.992-19.76.123,6.907.059,3.343s-2.781,21.745.461,25.965.066,3.724.066,3.724l.154,8.705,20.287.126-4.476-25.062s-2.273-1.575-1.057-3.158-1.178-3.522-1.178-3.522l1.074-19.565s-1.189-3.38.21-3.819.184-3.345.184-3.345l.208-3.787-4.172-11.745Z"
                          transform="translate(-520.984 -322.905)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_146"
                          data-name="Path 146"
                          d="M698.072,342.509l2.014-8.208,1.439-2.736.576-7.817c0-21.106-3.466-17.591-3.466-17.591l-2-7.423-9.208-.391-5.9,7.622-4.892,2.345-1.916,13.52,3.5,14.426.863,7.817c-1.647,4.129-1.507,2.176-.144,5.277l16.259,6.058c5.755,2.736,5.755-4.3,4.6-6.058S698.072,342.509,698.072,342.509Z"
                          transform="translate(-565.635 -244.833)"
                          fill="#3f3d56"
                        />
                        <circle
                          id="Ellipse_55"
                          data-name="Ellipse 55"
                          cx="8.536"
                          cy="8.536"
                          r="8.536"
                          transform="translate(116.668 34.072)"
                          fill="#ffb6b6"
                        />
                        <path
                          id="Path_147"
                          data-name="Path 147"
                          d="M699.506,307.834"
                          transform="translate(-582.615 -251.212)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_148"
                          data-name="Path 148"
                          d="M714.251,254.006a1.049,1.049,0,0,1-.584,1.534c-1.145.38-1.85,1.488-2.673,2.374s-2.141,1.617-3.188,1.016-1.116-2.127-1.991-2.955a2.772,2.772,0,0,0-3.269-.095l-.03.018a1.056,1.056,0,0,1-1.615-1.074q.526-2.8,1.052-5.6a18.822,18.822,0,0,1-3.939,5.957,4.334,4.334,0,0,1-1.709,1.2c-.6.19-1.18-.429-1.761-.317,1.445-1.263,6.3-9.361,5.433-14.774l-3.065.345a2.817,2.817,0,0,0-.685-1.292,3.31,3.31,0,0,1-.176,1.39c-.426.049-.855.095-1.28.144a2.752,2.752,0,0,1-1.747-.187c-.849-.518-.875-1.744-.633-2.711a9.979,9.979,0,0,1,5.482-6.394c2.627-1.229,3.4,1.508,6.172.633,5.467-1.727,9.657,1.226,11.134,6.7C716.418,244.524,715.517,249.4,714.251,254.006Z"
                          transform="translate(-577.65 -200.004)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_149"
                          data-name="Path 149"
                          d="M808.554,741.566H798.1l-.045-.082c-.134-.242-.263-.5-.383-.763a28.769,28.769,0,0,1-1.933-7.267l-.3-1.753a1.083,1.083,0,0,1,1.7-1.059q2.383,1.734,4.768,3.464c.6.438,1.289.945,1.948,1.493.063-.309.13-.618.2-.923a1.083,1.083,0,0,1,1.978-.342l1.223,1.965c.892,1.435,1.679,2.849,1.519,4.374a.249.249,0,0,1,0,.055,3.449,3.449,0,0,1-.178.734Z"
                          transform="translate(-648.32 -536.455)"
                          fill="#f0f0f0"
                        />
                        <path
                          id="Path_150"
                          data-name="Path 150"
                          d="M653.58,764.862l-99.329.1a.375.375,0,0,1,0-.75l99.329-.1a.375.375,0,0,1,0,.75Z"
                          transform="translate(-482.855 -559.526)"
                          fill="#fff"
                        />
                        <path
                          id="Path_151"
                          data-name="Path 151"
                          d="M558.916,290.285l-10.926-9.227a1.847,1.847,0,0,1-.219-2.6l2.011-2.381a1.847,1.847,0,0,1,2.6-.219l10.926,9.227a1.847,1.847,0,0,1,.219,2.6l-2.011,2.381A1.847,1.847,0,0,1,558.916,290.285Z"
                          transform="translate(-478.375 -229.2)"
                          fill="#3f3d56"
                        />
                        <path
                          id="Path_152"
                          data-name="Path 152"
                          d="M550.725,279.723a1,1,0,0,0,.118,1.4l10.926,9.227a1,1,0,0,0,1.4-.118l2.011-2.381a1,1,0,0,0-.118-1.4l-10.926-9.227a1,1,0,0,0-1.4.118Z"
                          transform="translate(-480.535 -230.271)"
                          fill="#fff"
                        />
                        <path
                          id="Path_153"
                          data-name="Path 153"
                          d="M566.207,288.056a.42.42,0,0,1-.332.176l-1.717.039a.42.42,0,0,1-.019-.84l1.123-.026-1.106-2.918a.42.42,0,1,1,.786-.3l1.316,3.474a.42.42,0,0,1-.044.384Z"
                          transform="translate(-489.604 -235.019)"
                          fill="#6c63ff"
                        />
                        <path
                          id="Path_154"
                          data-name="Path 154"
                          d="M565.087,299.311a2.863,2.863,0,0,1,2.3,3.739l8.059,6.208-5,1.72-6.887-6.159a2.878,2.878,0,0,1,1.527-5.508Z"
                          transform="translate(-488.263 -245.334)"
                          fill="#ffb6b6"
                        />
                        <path
                          id="Path_155"
                          data-name="Path 155"
                          d="M618.7,309.129l-20.634,12.686s-8.319-3.078-6.693-4.5-3.55-2.388-3.55-2.388l-5.718-3.847-3.318,3.856s.6,6.263,1.633,5.179,2.566,1.878,2.566,1.878.454,3.308,3.383,2.476,10.785,7.894,10.785,7.894l25.394-9.471Z"
                          transform="translate(-499.919 -251.872)"
                          fill="#3f3d56"
                        />
                        <path
                          id="Path_156"
                          data-name="Path 156"
                          d="M575.685,237.787a1.54,1.54,0,0,1-1.232-.616l-3.779-5.039a1.541,1.541,0,1,1,2.465-1.849l2.472,3.3,6.35-9.525a1.541,1.541,0,0,1,2.564,1.709L576.967,237.1a1.541,1.541,0,0,1-1.239.686Z"
                          transform="translate(-494.15 -194.968)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <Typography variant="h5">
                      {" "}
                      Start your free 30-day trial{" "}
                    </Typography>
                    <Typography variant="p">
                      {" "}
                      No credit card needed.{" "}
                    </Typography>
                  </div>
                </div>
              </SbizzlAnimate>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default Step1;
