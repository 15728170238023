import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import {
  Input,
  Button,
  Typography,
  Grid,
  FormHelperText,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Link,
  CircularProgress
} from "@material-ui/core";
import SbizzlAnimate from "../common/SbizzlAnimate";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LogoPath from "../assets/img/logo-lg.png";
import axios from "axios";
import config from "../config";

class Step5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initForm: {
        password: "",
        showPassword: false
      },
      showCreatePassword: true,
      queryDidLoad: false,
      validPassword: false,
      isLoading: false
    };
  }

  onSaveInfo = async (values) => {
    this.setState({
      isLoading: true
    });

    let tokenParam = new URLSearchParams(this.props.location.search).get(
      "token"
    );

    let passwordObj = {
      password: values.password
    };

    axios
      .post(
        `${config.api.gatewayService}/createOwnerPassword`,
        {
          passwordObj
        },
        {
          headers: { token: tokenParam }
        }
      )
      .then((passRes) => {
        window.location.href = passRes.data.data.signInLink;
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false
        });
      });
  };

  handleClickShowPassword = (values, setValues) => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  initializeScreen = () => {
    if (!this.state.queryDidLoad) {
      let tokenParam = new URLSearchParams(this.props.location.search).get(
        "token"
      );

      axios
        .post(
          `${config.api.gatewayService}/userByUserId`,
          {},
          {
            headers: { token: tokenParam }
          }
        )
        .then((userRes) => {
          if (userRes.data.data.firstLogin) {
            this.setState(
              {
                showCreatePassword: true
              },
              () => {
                this.setState({
                  queryDidLoad: true
                });
              }
            );
          } else {
            this.setState(
              {
                showCreatePassword: false
              },
              () => {
                this.setState({
                  queryDidLoad: true
                });
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  validatePassword = (e) => {
    const strongRegex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*||0-9])(?=.{8,})/
    );

    if (/^(?=.*[a-z])/.test(e.target.value.trim()) === true) {
      this.setState({
        pwdLowerCase: true
      });
    } else if (/^(?!=.*[a-z])/.test(e.target.value.trim()) === true) {
      this.setState({
        pwdLowerCase: false,
        validPassword: false
      });
    }

    if (/^(?=.*[A-Z])/.test(e.target.value.trim()) === true) {
      this.setState({
        pwdUpperCase: true
      });
    } else if (/^(?!=.*[A-Z])/.test(e.target.value.trim()) === true) {
      this.setState({
        pwdUpperCase: false,
        validPassword: false
      });
    }

    if (/^(?=.*[!@#$%^&*||0-9])/.test(e.target.value.trim()) === true) {
      this.setState({
        pwdNoSpecialChar: true
      });
    } else if (/^(?!=.*[!@#$%^&*||0-9])/.test(e.target.value.trim()) === true) {
      this.setState({
        pwdNoSpecialChar: false,
        validPassword: false
      });
    }

    if (/^(?=.{8,})/.test(e.target.value.trim()) === true) {
      this.setState({
        pwdLength: true
      });
    } else if (/^(?!=.{8,})/.test(e.target.value.trim()) === true) {
      this.setState({
        pwdLength: false,
        validPassword: false
      });
    }

    if (strongRegex.test(e.target.value.trim()) === true) {
      this.setState({
        validPassword: true
      });
    } else {
      this.setState({
        validPassword: false
      });
    }
  };

  render() {
    this.initializeScreen();
    const validateSchema = yup.object().shape({
      password: yup.string().required("Please enter password"),
      showPassword: yup.boolean()
    });
    return (
      <Formik
        initialValues={this.state.initForm}
        enableReinitialize={true}
        validationSchema={validateSchema}
        onSubmit={(values) => {
          this.onSaveInfo(values);
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            setValues,
            handleBlur,
            handleSubmit
          } = props;
          return this.state.showCreatePassword ? (
            <div className="ViewContainer">
              <SbizzlAnimate animation="transition.fadeIn" delay={200}>
                <>
                  <span className="bgProgress" />
                  <div
                    className="stepper-progress-bar"
                    style={{
                      width: !this.state.showCreatePassword ? "100%" : "66.66%"
                    }}
                  />
                </>
              </SbizzlAnimate>
              <SbizzlAnimate animation="transition.slideRightIn" delay={200}>
                <div className="header">
                  <img src={LogoPath} alt="logo" />
                  {!this.state.showCreatePassword && (
                    <Typography component="p">
                      Have an account?{" "}
                      <Link href="https://app.sbizzl.com/login" target="_blank">
                        Sign in
                      </Link>
                    </Typography>
                  )}
                </div>
              </SbizzlAnimate>

              <Form className="ViewComponent">
                <SbizzlAnimate animation="transition.slideRightIn" delay={250}>
                  <div className="formWraper">
                    <div className="forTextStyle">
                      <Typography variant="h3" className="secondaryColor">
                        Create your password
                      </Typography>
                    </div>

                    <Grid item xs={12} sm={12}>
                      <div className="inputControl">
                        <Input
                          id="password"
                          error={errors.password && touched.password}
                          helperText={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                          value={values.password}
                          onChange={(e) => {
                            this.validatePassword(e);

                            setValues({
                              ...values,
                              password: e.target.value
                            });
                          }}
                          onBlur={handleBlur}
                          aria-describedby="password"
                          placeholder="Password"
                          name="password"
                          type={values.showPassword ? "text" : "password"}
                          className="customInput"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  this.handleClickShowPassword(
                                    values,
                                    setValues
                                  )
                                }
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                                className="inputButtonAd"
                              >
                                {!values.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText className="errorText">
                          {errors.password}
                        </FormHelperText>
                      </div>
                    </Grid>

                    <List className="listStepPwd">
                      <ListItem key={1}>
                        <span className="outerSpanCircle">
                          {this.state.pwdLength && (
                            <span className="innerSpanCircle" />
                          )}
                        </span>
                        <ListItemText primary={"At least 8 characters"} />
                      </ListItem>

                      <ListItem key={2}>
                        <span className="outerSpanCircle">
                          {this.state.pwdLowerCase && (
                            <span className="innerSpanCircle" />
                          )}
                        </span>
                        <ListItemText primary={"One lower character"} />
                      </ListItem>

                      <ListItem key={3}>
                        <span className="outerSpanCircle">
                          {this.state.pwdUpperCase && (
                            <span className="innerSpanCircle" />
                          )}
                        </span>
                        <ListItemText primary={"One capital character"} />
                      </ListItem>

                      <ListItem key={4}>
                        <span className="outerSpanCircle">
                          {this.state.pwdNoSpecialChar && (
                            <span className="innerSpanCircle" />
                          )}
                        </span>
                        <ListItemText
                          primary={
                            "One number, symbol or white space character"
                          }
                        />
                      </ListItem>
                    </List>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="nextButton"
                      onClick={handleSubmit}
                      size="large"
                      disabled={
                        !this.state.validPassword || this.state.isLoading
                      }
                    >
                      Finish
                    </Button>
                    {this.state.isLoading && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: "absolute",
                          top: "90.4%",
                          left: "47%"
                        }}
                      />
                    )}
                  </div>
                </SbizzlAnimate>
                <SbizzlAnimate animation="transition.slideRightIn" delay={400}>
                  <div className="imagBlockContainer">
                    <div className="imagBlock">
                      <svg
                        id="undraw_secure_login_pdn4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="212"
                        height="148"
                        viewBox="0 0 212 148"
                      >
                        <ellipse
                          id="Ellipse_57"
                          data-name="Ellipse 57"
                          cx="42.428"
                          cy="3.222"
                          rx="42.428"
                          ry="3.222"
                          transform="translate(0 141.555)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="Path_195"
                          data-name="Path 195"
                          d="M324.272,235.025c7.384-3.141,16.58-1.151,25.562.23.084-1.672,1.1-3.708.035-4.875a3.545,3.545,0,0,1-.395-4.4c1.984-3.831-.858-7.907-3.728-11.3a6.356,6.356,0,0,0-5.3-2.227l-5.316.38a6.367,6.367,0,0,0-5.653,4.551h0c-1.269,1.726-1.949,3.448-1.521,5.161-1.9,1.3-2.221,2.868-1.366,4.636a2.665,2.665,0,0,1-.033,3.251,15.008,15.008,0,0,0-2.232,4.432Z"
                          transform="translate(-292.005 -202.151)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_196"
                          data-name="Path 196"
                          d="M554.035,624.4H403.546a5.046,5.046,0,0,1-5.046-5.046h0a680.185,680.185,0,0,1,160.581,0h0a5.046,5.046,0,0,1-5.046,5.046Z"
                          transform="translate(-347.081 -496.477)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_197"
                          data-name="Path 197"
                          d="M559.081,341.529l-160.581-.3,18.611-31.315.089-.148V240.756a6.378,6.378,0,0,1,6.379-6.379H533.112a6.378,6.378,0,0,1,6.379,6.379v69.488Z"
                          transform="translate(-347.081 -218.2)"
                          fill="#3f3d56"
                        />
                        <path
                          id="Path_198"
                          data-name="Path 198"
                          d="M485.69,250.957a2.08,2.08,0,0,0-2.078,2.078v62.926a2.08,2.08,0,0,0,2.078,2.078H596.405a2.08,2.08,0,0,0,2.078-2.078V253.034a2.08,2.08,0,0,0-2.078-2.078Z"
                          transform="translate(-409.338 -230.327)"
                          fill="#fff"
                        />
                        <path
                          id="Path_199"
                          data-name="Path 199"
                          d="M475.031,531.718a.893.893,0,0,0-.809.518L468.488,544.7a.89.89,0,0,0,.809,1.263H589.891a.89.89,0,0,0,.8-1.289l-6.233-12.467a.886.886,0,0,0-.8-.492Z"
                          transform="translate(-398.215 -435.808)"
                          fill="#2f2e41"
                        />
                        <circle
                          id="Ellipse_58"
                          data-name="Ellipse 58"
                          cx="1.336"
                          cy="1.336"
                          r="1.336"
                          transform="translate(130.285 16.919)"
                          fill="#fff"
                        />
                        <path
                          id="Path_200"
                          data-name="Path 200"
                          d="M645.356,593.619a.892.892,0,0,0-.86.659l-1.438,5.343a.89.89,0,0,0,.86,1.122h27.356a.89.89,0,0,0,.841-1.182l-1.849-5.343a.891.891,0,0,0-.841-.6Z"
                          transform="translate(-525.607 -481.1)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_201"
                          data-name="Path 201"
                          d="M386.686,337.135v1.187H264.306l.092-.148v-1.039Z"
                          transform="translate(-194.276 -246.701)"
                          fill="#2f2e41"
                        />
                        <circle
                          id="Ellipse_59"
                          data-name="Ellipse 59"
                          cx="20.778"
                          cy="20.778"
                          r="20.778"
                          transform="translate(168.219 0)"
                          fill="#02a8ee"
                        />
                        <path
                          id="Path_202"
                          data-name="Path 202"
                          d="M892.76,250.456H875.545a1.189,1.189,0,0,1-1.187-1.187V238.88a1.189,1.189,0,0,1,1.187-1.187H892.76a1.189,1.189,0,0,1,1.187,1.187v10.389a1.188,1.188,0,0,1-1.187,1.187ZM875.545,238.88v10.389h17.217V238.88Z"
                          transform="translate(-695.156 -220.625)"
                          fill="#fff"
                        />
                        <path
                          id="Path_203"
                          data-name="Path 203"
                          d="M901.624,213.006H890.938v-4.749c0-3.44,2.247-5.936,5.343-5.936s5.343,2.5,5.343,5.936Zm-9.5-1.187h8.311v-3.562c0-2.8-1.709-4.749-4.156-4.749s-4.156,1.953-4.156,4.749Z"
                          transform="translate(-707.285 -194.752)"
                          fill="#fff"
                        />
                        <circle
                          id="Ellipse_60"
                          data-name="Ellipse 60"
                          cx="1.187"
                          cy="1.187"
                          r="1.187"
                          transform="translate(187.809 21.965)"
                          fill="#fff"
                        />
                        <path
                          id="Path_204"
                          data-name="Path 204"
                          d="M619.428,354.835H533.864a1.586,1.586,0,0,1-1.585-1.585V332.026a1.586,1.586,0,0,1,1.585-1.585h85.564a1.586,1.586,0,0,1,1.585,1.585v21.225A1.586,1.586,0,0,1,619.428,354.835Zm-85.564-23.76a.952.952,0,0,0-.951.951v21.225a.952.952,0,0,0,.951.951h85.564a.952.952,0,0,0,.951-.951V332.026a.952.952,0,0,0-.951-.951Z"
                          transform="translate(-444.663 -288.521)"
                          fill="#e6e6e6"
                        />
                        <circle
                          id="Ellipse_61"
                          data-name="Ellipse 61"
                          cx="6.454"
                          cy="6.454"
                          r="6.454"
                          transform="translate(96.255 46.847)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="Path_205"
                          data-name="Path 205"
                          d="M640.273,356.8a1.076,1.076,0,1,0,0,2.151h50.714a1.076,1.076,0,0,0,0-2.151Z"
                          transform="translate(-522.889 -307.805)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="Path_206"
                          data-name="Path 206"
                          d="M640.273,380.842a1.076,1.076,0,1,0,0,2.152H662.1a1.076,1.076,0,0,0,0-2.152Z"
                          transform="translate(-522.784 -325.394)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="Path_207"
                          data-name="Path 207"
                          d="M423.391,357.342l-15.817,8.409-.2-7.008a46.843,46.843,0,0,0,14.616-4.805l1.654-4.087a2.771,2.771,0,0,1,4.709-.719h0a2.771,2.771,0,0,1-.244,3.778Z"
                          transform="translate(-352.897 -301.456)"
                          fill="#ffb8b8"
                        />
                        <path
                          id="Path_208"
                          data-name="Path 208"
                          d="M322.8,534.38h0a3.108,3.108,0,0,1,.395-2.514l3.475-5.332a6.072,6.072,0,0,1,7.877-2.078h0c-1.46,2.486-1.257,4.666.5,6.561a31.584,31.584,0,0,0-7.5,5.114,3.108,3.108,0,0,1-4.749-1.75Z"
                          transform="translate(-290.835 -429.995)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_209"
                          data-name="Path 209"
                          d="M390.3,473.672h0a5.962,5.962,0,0,1-5.067,2.895l-23.083.175-1-5.806,10.211-3-8.609-6.607,9.41-11.012,17.092,15.93a5.962,5.962,0,0,1,1.048,7.429Z"
                          transform="translate(-319.032 -376.246)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_210"
                          data-name="Path 210"
                          d="M323.4,469.75h-5.406c-4.854-33.27-9.794-66.634,4.805-79.086l17.219,2.8-2.2,14.616-9.61,11.012Z"
                          transform="translate(-283.67 -332.676)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_211"
                          data-name="Path 211"
                          d="M338.8,687.19h0a3.108,3.108,0,0,1-2.514-.394l-5.87-.789a6.072,6.072,0,0,1-2.079-7.877h0c2.486,1.46,4.666,1.256,6.561-.5,1.339,2.7,3.545,2.538,5.652,4.816a3.108,3.108,0,0,1-1.75,4.749Z"
                          transform="translate(-294.474 -542.579)"
                          fill="#2f2e41"
                        />
                        <path
                          id="Path_212"
                          data-name="Path 212"
                          d="M371.9,270.27l-10.011-2.4c1.663-3.4,1.8-7.19,1-11.212l6.807-.2A52.263,52.263,0,0,0,371.9,270.27Z"
                          transform="translate(-319.557 -234.349)"
                          fill="#ffb8b8"
                        />
                        <path
                          id="Path_213"
                          data-name="Path 213"
                          d="M358.36,325.322c-7.288,4.966-12.437.17-16.366-7.23.546-4.528-.338-9.946-1.975-15.834a10.778,10.778,0,0,1,6.581-13h0l8.609,3.6c7.31,5.959,8.749,12.413,6.007,19.221Z"
                          transform="translate(-303.246 -258.39)"
                          fill="#02a8ee"
                        />
                        <path
                          id="Path_214"
                          data-name="Path 214"
                          d="M282.383,304.181l-8.009,4.2,14.215,8.409,1.978,4.879a2.588,2.588,0,0,1-1.555,3.419h0a2.588,2.588,0,0,1-3.427-2.294l-.2-3.4-18.068-5.962a4.225,4.225,0,0,1-2.651-2.581h0a4.225,4.225,0,0,1,1.585-4.915l14.529-9.967Z"
                          transform="translate(-248.059 -263.298)"
                          fill="#ffb8b8"
                        />
                        <path
                          id="Path_215"
                          data-name="Path 215"
                          d="M331.695,299.224c-3.34-1.464-6.377.126-10.211,1.4l-.6-10.612c3.807-2.029,7.436-2.576,10.812-1Z"
                          transform="translate(-289.362 -257.532)"
                          fill="#02a8ee"
                        />
                        <circle
                          id="Ellipse_62"
                          data-name="Ellipse 62"
                          cx="6.392"
                          cy="6.392"
                          r="6.392"
                          transform="translate(39.748 14.579)"
                          fill="#ffb8b8"
                        />
                        <path
                          id="Path_216"
                          data-name="Path 216"
                          d="M365.732,233.6c-6.328.62-11.15-.415-14.232-3.362v-2.373h13.7Z"
                          transform="translate(-311.946 -213.438)"
                          fill="#2f2e41"
                        />
                      </svg>
                    </div>
                  </div>
                </SbizzlAnimate>
              </Form>
            </div>
          ) : (
            <div className="ViewContainer">
              <SbizzlAnimate animation="transition.fadeIn" delay={200}>
                <>
                  <span className="bgProgress" />
                  <div
                    className="stepper-progress-bar"
                    style={{
                      width: !this.state.showCreatePassword ? "100%" : "66.66%"
                    }}
                  />
                </>
              </SbizzlAnimate>
              <SbizzlAnimate animation="transition.slideRightIn" delay={200}>
                <div className="header">
                  <img src={LogoPath} alt="logo" />
                </div>
              </SbizzlAnimate>
              <div className="ViewComponent signupProcess">
                <SbizzlAnimate animation="transition.slideRightIn" delay={250}>
                  <div className="signupProcessInner">
                    <svg
                      id="undraw_completing_re_i7ap"
                      xmlns="http://www.w3.org/2000/svg"
                      width="194"
                      height="231"
                      viewBox="0 0 194 231"
                    >
                      <path
                        id="Path_217"
                        data-name="Path 217"
                        d="M461.649,492.808a21.439,21.439,0,0,0-15.38-6.277h-.228a21.96,21.96,0,0,1-11.071-2.99l-.35-.2.282-.29a21.407,21.407,0,0,0,3.775-5.344,6.473,6.473,0,0,1-5.224,1.688,6.628,6.628,0,0,1-4.865-3.116c-2.064-3.349-4.524-8.1-4.524-11.721a22.028,22.028,0,0,1,7.186-16.254,13.717,13.717,0,0,0,4.531-10.228V437.8a31.119,31.119,0,0,1,.925-7.533l.061-.244.256.009a21.032,21.032,0,0,0,4.918-.412,16.6,16.6,0,0,1-4.41-1.173l-.275-.111.093-.282c6.438-3.986,20.972-1.922,29.408-21.222,6.84-15.65,30.975,13.892,30.975,30.969,0,1.742-1.724,4.045-.434,5.2,13.954,12.449,4.326,20.444,1.29,28.488-.72,1.909.841,4,.841,6.069,0,.35-.009.712-.027,1.075l-.027.558-.465-.31a15.41,15.41,0,0,1-2.545-2.088,13.431,13.431,0,0,1-.426,11.595,20.533,20.533,0,0,1-4.205,5.873,21.956,21.956,0,0,1-30.085-1.445Z"
                        transform="translate(-386.481 -303.156)"
                        fill="#e6e6e6"
                      />
                      <path
                        id="Path_218"
                        data-name="Path 218"
                        d="M567.109,634.03a.4.4,0,0,0,.391-.34,132.329,132.329,0,0,0,.184-27.746c-1.364-14.56-5.745-35.531-18.869-52.755a.4.4,0,1,0-.629.479c13.01,17.075,17.356,37.892,18.711,52.349a131.436,131.436,0,0,1-.18,27.561.4.4,0,0,0,.392.451Z"
                        transform="translate(-468.63 -403.03)"
                        fill="#3f3d56"
                      />
                      <path
                        id="Path_219"
                        data-name="Path 219"
                        d="M495.944,606.085a.4.4,0,0,0,.233-.715,54.473,54.473,0,0,0-12.026-6.122c-6.635-2.429-16.725-4.631-27.112-1.325a.4.4,0,1,0,.24.753c10.174-3.238,20.081-1.072,26.6,1.315a53.579,53.579,0,0,1,11.833,6.019A.393.393,0,0,0,495.944,606.085Z"
                        transform="translate(-408.116 -431.457)"
                        fill="#3f3d56"
                      />
                      <path
                        id="Path_220"
                        data-name="Path 220"
                        d="M602.8,491.27a.4.4,0,0,0-.7-.284,54.475,54.475,0,0,0-6.972,11.554c-2.9,6.443-5.824,16.348-3.274,26.946a.4.4,0,1,0,.768-.185c-2.5-10.381.377-20.106,3.227-26.437a53.578,53.578,0,0,1,6.856-11.369.393.393,0,0,0,.092-.226Z"
                        transform="translate(-496.965 -361.648)"
                        fill="#3f3d56"
                      />
                      <path
                        id="Path_221"
                        data-name="Path 221"
                        d="M774.211,725.739H742.746l-.135-.245c-.4-.73-.791-1.5-1.154-2.3-3.241-6.939-4.611-14.874-5.82-21.876l-.91-5.278a3.258,3.258,0,0,1,5.129-3.188q7.173,5.219,14.351,10.428c1.812,1.318,3.881,2.844,5.863,4.493.191-.929.391-1.86.591-2.779a3.261,3.261,0,0,1,5.955-1.03l3.681,5.914c2.685,4.319,5.055,8.576,4.572,13.166a.713.713,0,0,1-.013.167,10.381,10.381,0,0,1-.534,2.21Z"
                        transform="translate(-592.342 -495.158)"
                        fill="#f0f0f0"
                      />
                      <path
                        id="Path_222"
                        data-name="Path 222"
                        d="M781.9,376.1l-6.072-22.476a3.758,3.758,0,0,0-4.558-2.649h0a3.758,3.758,0,0,0-2.652,4.765l7.023,23.153,3.675,14.249a3.279,3.279,0,1,0,4.2.188Z"
                        transform="translate(-614.687 -268.845)"
                        fill="#ffb8b8"
                      />
                      <path
                        id="Path_223"
                        data-name="Path 223"
                        d="M614.718,370.235l18.45-14.2a3.758,3.758,0,0,0,.706-5.225h0a3.758,3.758,0,0,0-5.417-.63l-18.713,15.337-11.764,8.84a3.279,3.279,0,1,0,1.433,3.957Z"
                        transform="translate(-498.447 -267.789)"
                        fill="#ffb8b8"
                      />
                      <path
                        id="Path_224"
                        data-name="Path 224"
                        d="M700,418.876l-.813,18.163,25.536-5.55c-2.173-7.3-3.651-8.543-2.018-13.623Z"
                        transform="translate(-568.757 -313.224)"
                        fill="#ffb6b6"
                      />
                      <path
                        id="Path_225"
                        data-name="Path 225"
                        d="M707.858,322.378h-8.509l-3.654,6.253-1.741-.1a6.273,6.273,0,0,0-6.619,6.263h0l2.145,3.325s-3.837,6.686,1.69,9.976c0,0-.986,6.2,0,6.131s3.256-1.328.986-.067-2.1,1.431-1.807,1.724l.294.294,23.874,4.009s.7-4.628.774-5.454-.429,1.193.075-.826,1.261-1.261.5-2.018,4.9-10.133,2.578-13.77l3.729-3.637h0c0-3.489-5.6-6.317-9.092-6.317h-.447Z"
                        transform="translate(-560.92 -249.944)"
                        fill="#02a8ee"
                      />
                      <path
                        id="Path_226"
                        data-name="Path 226"
                        d="M364.9,637.178H361.46L359.822,623.9H364.9Z"
                        transform="translate(-238.404 -413.599)"
                        fill="#ffb8b8"
                      />
                      <path
                        id="Path_227"
                        data-name="Path 227"
                        d="M656.641,771.735a1.465,1.465,0,0,0,1.456,1.458h6.483l.2-.416.917-1.892.355,1.892.079.416h2.445l-.035-.419-.652-7.819-.853-.052-3.685-.218-.905-.055v2.335C661.759,767.694,656.368,770.457,656.641,771.735Z"
                        transform="translate(-540.534 -543.074)"
                        fill="#2f2e41"
                      />
                      <path
                        id="Path_228"
                        data-name="Path 228"
                        d="M503.778,614.636l-2.9,1.863-8.565-10.285,4.275-2.749Z"
                        transform="translate(-326.246 -400.078)"
                        fill="#ffb8b8"
                      />
                      <path
                        id="Path_229"
                        data-name="Path 229"
                        d="M820.7,750.138a1.465,1.465,0,0,0,2.013.439l5.452-3.507-.056-.459-.253-2.087,1.322,1.4.291.308,2.057-1.323-.256-.334-4.779-6.223-.746.417-3.218,1.81-.791.443L823,742.984C822.815,743.97,819.776,749.211,820.7,750.138Z"
                        transform="translate(-649.157 -525.682)"
                        fill="#2f2e41"
                      />
                      <path
                        id="Path_230"
                        data-name="Path 230"
                        d="M676.759,790.466l-106.638.1a.4.4,0,0,1,0-.805l106.638-.1a.4.4,0,1,1,0,.805Z"
                        transform="translate(-483.162 -559.642)"
                        fill="#cacaca"
                      />
                      <path
                        id="Path_231"
                        data-name="Path 231"
                        d="M701.074,452.085s-16.323,8.634-24.956,2.743c0,0-1.889,9.147-2.645,9.9s-1.963,1.087-1.107,1.931,1.331,1.984-.294,3.432-3.246.989-1.679,2.48-4.74,38.07-4.74,38.07,1.009,34.017,0,35.046-2.358,1.259-1.053,2.531,1.305.286,1.305,2.04v1.755l.6,1.744h8.191s1.977-2.3,0-4.291.6-2.54,1.2-4.412,1.612.147.6-1.871-1.494-1.634-1.5-2.961,14.874-53.295,14.874-53.295l1.8,28.763,21.684,31.5,6.383-4.787s-4.647-6.542-3.56-7.441-3.2-2.027-1.85-3.042-1.9-2.037-.676-3.382-1.691-4.734-3.381-4.734-4.064-5.65-4.064-5.65l3.542-44.633Z"
                        transform="translate(-545.602 -336.217)"
                        fill="#2f2e41"
                      />
                      <path
                        id="Path_232"
                        data-name="Path 232"
                        d="M705.079,267.7a5.031,5.031,0,0,1-2.143-.092c-2.188-.32-2.486.06-4.122,1.548-2.37,2.155-1.022,15.3-2.187,18.151l-.376.277a13.539,13.539,0,0,0,2.191-.613c.4-1.269.805-2.541,1.211-3.809a11.153,11.153,0,0,1,.715,3.076,17.187,17.187,0,0,1,3.477-1.493c1.185-.8,9.76-1.612,10.862-1.966C717.5,273.26,713,269.41,705.079,267.7Z"
                        transform="translate(-566.799 -213.543)"
                        fill="#2f2e41"
                      />
                      <circle
                        id="Ellipse_63"
                        data-name="Ellipse 63"
                        cx="8.194"
                        cy="8.194"
                        r="8.194"
                        transform="translate(132.978 52.454)"
                        fill="#ffb8b8"
                      />
                      <path
                        id="Path_233"
                        data-name="Path 233"
                        d="M742.434,257.805a3.66,3.66,0,0,1-1.309-.459,1.245,1.245,0,0,1-.6-1.178,1.259,1.259,0,0,1,1.349-.894,3.668,3.668,0,0,1,1.626.658,2.52,2.52,0,0,0-2.147-2.258,5.232,5.232,0,0,1,2.3.22,1.79,1.79,0,0,1,1.186,1.8,2.939,2.939,0,0,1,2.893,1.794,4.887,4.887,0,0,1,0,3.561,1.258,1.258,0,0,1-.63.833,1.348,1.348,0,0,1-1.194-.359,28.555,28.555,0,0,1-4.981-4.6"
                        transform="translate(-596.115 -204.348)"
                        fill="#2f2e41"
                      />
                      <path
                        id="Path_234"
                        data-name="Path 234"
                        d="M720.951,275.024c-.347.164-.167.772-.436,1.044a1.11,1.11,0,0,1-.414.257,4.975,4.975,0,0,0-.351-1.478c-.035-.086-.093-.182-.184-.174-.106.013-.134.151-.136.255a4.275,4.275,0,0,1-.335,1.491l-.008.018-.235-.008c-.585-.023-1.882-1.526-2.17-1.017a19.079,19.079,0,0,0,2.439-6.559,2.908,2.908,0,0,0-.093-1.287,1.137,1.137,0,0,0-.946-.782.724.724,0,0,0-.749.827l-.146-.02c-1.357-1.1-.187-3.025-1.766-3.784-.535-.257-3.809.6-4.387.452a4.973,4.973,0,0,0-.366-1.773c-.035-.086-.093-.182-.184-.174-.106.013-.134.151-.136.255a4.274,4.274,0,0,1-.336,1.491,6.249,6.249,0,0,0-2.4.126c-.691.182-1.347.494-2.041.661a2.639,2.639,0,0,1-2.074-.227,2.336,2.336,0,0,1-.6-2.861,5.634,5.634,0,0,1,1.266-1.635c.4-.383.807-.752,1.218-1.12l1.1-.991a13.475,13.475,0,0,1,1.478-1.206,7.294,7.294,0,0,1,5.694-.994c1.16.277,2.712-.114,3.774.429a22.36,22.36,0,0,1,2.27,1.766c1.246.991.4.184,1.766.757C724.726,260.13,723.288,273.923,720.951,275.024Z"
                        transform="translate(-571.053 -205.66)"
                        fill="#2f2e41"
                      />
                      <circle
                        id="Ellipse_64"
                        data-name="Ellipse 64"
                        cx="50.815"
                        cy="50.815"
                        r="50.815"
                        transform="translate(8.677 27.702)"
                        fill="#fff"
                      />
                      <path
                        id="Path_235"
                        data-name="Path 235"
                        d="M387.994,290.48a51.321,51.321,0,1,1,51.322-51.321A51.38,51.38,0,0,1,387.994,290.48Zm0-101.629A50.307,50.307,0,1,0,438.3,239.158,50.364,50.364,0,0,0,387.994,188.851Z"
                        transform="translate(-328.503 -160.642)"
                        fill="#6c63ff"
                      />
                      <path
                        id="Path_236"
                        data-name="Path 236"
                        d="M429.748,314.288a5.651,5.651,0,0,1-4.522-2.262l-13.863-18.484a5.652,5.652,0,1,1,9.044-6.782l9.07,12.092,23.294-34.941a5.653,5.653,0,0,1,9.406,6.271l-27.726,41.589a5.655,5.655,0,0,1-4.546,2.515Z"
                        transform="translate(-377.286 -209.524)"
                        fill="#02a8ee"
                      />
                      <ellipse
                        id="Ellipse_65"
                        data-name="Ellipse 65"
                        cx="1.778"
                        cy="2.555"
                        rx="1.778"
                        ry="2.555"
                        transform="matrix(0.144, -0.99, 0.99, 0.144, 106.297, 31.9)"
                        fill="#e6e6e6"
                      />
                      <ellipse
                        id="Ellipse_66"
                        data-name="Ellipse 66"
                        cx="1.778"
                        cy="2.555"
                        rx="1.778"
                        ry="2.555"
                        transform="matrix(0.144, -0.99, 0.99, 0.144, 122.887, 30.278)"
                        fill="#3f3d56"
                      />
                      <ellipse
                        id="Ellipse_67"
                        data-name="Ellipse 67"
                        cx="1.778"
                        cy="2.555"
                        rx="1.778"
                        ry="2.555"
                        transform="matrix(0.144, -0.99, 0.99, 0.144, 101.754, 18.439)"
                        fill="#ff6584"
                      />
                      <ellipse
                        id="Ellipse_68"
                        data-name="Ellipse 68"
                        cx="1.615"
                        cy="1.123"
                        rx="1.615"
                        ry="1.123"
                        transform="matrix(0.008, -1, 1, 0.008, 97.596, 13.5)"
                        fill="#e6e6e6"
                      />
                      <ellipse
                        id="Ellipse_69"
                        data-name="Ellipse 69"
                        cx="1.615"
                        cy="1.123"
                        rx="1.615"
                        ry="1.123"
                        transform="matrix(0.008, -1, 1, 0.008, 95.151, 3.23)"
                        fill="#3f3d56"
                      />
                      <ellipse
                        id="Ellipse_70"
                        data-name="Ellipse 70"
                        cx="1.615"
                        cy="1.123"
                        rx="1.615"
                        ry="1.123"
                        transform="matrix(0.008, -1, 1, 0.008, 114.163, 21.304)"
                        fill="#e6e6e6"
                      />
                      <ellipse
                        id="Ellipse_71"
                        data-name="Ellipse 71"
                        cx="1.615"
                        cy="1.123"
                        rx="1.615"
                        ry="1.123"
                        transform="matrix(0.008, -1, 1, 0.008, 106.145, 25.316)"
                        fill="#ff6584"
                      />
                      <ellipse
                        id="Ellipse_72"
                        data-name="Ellipse 72"
                        cx="1.615"
                        cy="1.123"
                        rx="1.615"
                        ry="1.123"
                        transform="matrix(0.008, -1, 1, 0.008, 117.306, 32.731)"
                        fill="#3f3d56"
                      />
                      <ellipse
                        id="Ellipse_73"
                        data-name="Ellipse 73"
                        cx="1.778"
                        cy="2.555"
                        rx="1.778"
                        ry="2.555"
                        transform="matrix(0.144, -0.99, 0.99, 0.144, 11.174, 151.835)"
                        fill="#e6e6e6"
                      />
                      <ellipse
                        id="Ellipse_74"
                        data-name="Ellipse 74"
                        cx="1.778"
                        cy="2.555"
                        rx="1.778"
                        ry="2.555"
                        transform="matrix(0.144, -0.99, 0.99, 0.144, 27.802, 150.217)"
                        fill="#3f3d56"
                      />
                      <ellipse
                        id="Ellipse_75"
                        data-name="Ellipse 75"
                        cx="1.778"
                        cy="2.555"
                        rx="1.778"
                        ry="2.555"
                        transform="matrix(0.144, -0.99, 0.99, 0.144, 6.621, 138.414)"
                        fill="#ff6584"
                      />
                      <ellipse
                        id="Ellipse_76"
                        data-name="Ellipse 76"
                        cx="1.615"
                        cy="1.123"
                        rx="1.615"
                        ry="1.123"
                        transform="matrix(0.008, -1, 1, 0.008, 2.45, 133.491)"
                        fill="#e6e6e6"
                      />
                      <ellipse
                        id="Ellipse_77"
                        data-name="Ellipse 77"
                        cx="1.615"
                        cy="1.123"
                        rx="1.615"
                        ry="1.123"
                        transform="matrix(0.008, -1, 1, 0.008, 0, 123.251)"
                        fill="#3f3d56"
                      />
                      <ellipse
                        id="Ellipse_78"
                        data-name="Ellipse 78"
                        cx="1.615"
                        cy="1.123"
                        rx="1.615"
                        ry="1.123"
                        transform="matrix(0.008, -1, 1, 0.008, 19.053, 141.272)"
                        fill="#e6e6e6"
                      />
                      <ellipse
                        id="Ellipse_79"
                        data-name="Ellipse 79"
                        cx="1.615"
                        cy="1.123"
                        rx="1.615"
                        ry="1.123"
                        transform="matrix(0.008, -1, 1, 0.008, 11.018, 145.272)"
                        fill="#ff6584"
                      />
                      <ellipse
                        id="Ellipse_80"
                        data-name="Ellipse 80"
                        cx="1.615"
                        cy="1.123"
                        rx="1.615"
                        ry="1.123"
                        transform="matrix(0.008, -1, 1, 0.008, 22.203, 152.664)"
                        fill="#3f3d56"
                      />
                    </svg>
                    <Typography variant="p" className="textPrimary">
                      You have already completed your signup process
                    </Typography>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      className="nextButton mt-0"
                      size="large"
                      onClick={() => {
                        window.location.href = "https://app.sbizzl.com/login";
                      }}
                    >
                      Login
                    </Button>
                  </div>
                </SbizzlAnimate>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default Step5;
