import React, { Component } from "react";
import { Typography, Link } from "@material-ui/core";
import SbizzlAnimate from "../common/SbizzlAnimate";

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="ViewComponent">
        <SbizzlAnimate animation="transition.slideRightIn" delay={250}>
          <div className="formWraperDiv">
            <Typography variant="h3" className="secondaryColor">
              Check your email
            </Typography>
            <Typography variant="p" className="textPrimary">
              We need to confirm your email address.
            </Typography>
            <Typography variant="p" className="textSecondary">
              Please confirm by clicking on the emailed link sent to your
              provided email address:{" "}
              <span style={{ color: "rgb(35, 59, 113)" }}>
                {this.props.email}
              </span>
            </Typography>

            {/* <div className="formLinkView">
              <Typography  variant="p" className="textSecondary">
                Didn't get the email?
              </Typography>
              <Typography  variant="p" className="textSecondary">
                <Link>Resend or edit your email address</Link>
              </Typography>
            </div> */}
          </div>
        </SbizzlAnimate>
        <SbizzlAnimate animation="transition.slideRightIn" delay={400}>
          <div className="imagBlockContainer">
            <div className="imagBlock">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="154"
                height="138"
                viewBox="0 0 154 138"
              >
                <g id="undraw_mail_sent_re_0ofv" transform="translate(0 0)">
                  <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M472.023,247.107a.269.269,0,0,1-.154-.048l-74.717-52.113a1.353,1.353,0,0,0-1.548,0l-74.18,52.11a.27.27,0,0,1-.31-.443l74.179-52.11a1.9,1.9,0,0,1,2.167,0l74.717,52.113a.271.271,0,0,1-.155.493Z"
                    transform="translate(-319.376 -194.163)"
                    fill="#3f3d56"
                  />
                  <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M23.264,60.86,94.044,8.319,165.362,64.6l-67.671,40.18L60.952,96.391Z"
                    transform="translate(-16.969 -6.068)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M390.427,641.882H355.4a1.636,1.636,0,0,1,0-3.273h35.023a1.636,1.636,0,0,1,0,3.273Z"
                    transform="translate(-343.278 -518.8)"
                    fill="#02a8ee"
                  />
                  <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M367.97,615.882H355.4a1.636,1.636,0,0,1,0-3.273h12.566a1.636,1.636,0,0,1,0,3.273Z"
                    transform="translate(-343.278 -499.835)"
                    fill="#283c73"
                  />
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M483.883,297.311a2.036,2.036,0,0,1-.774-.152L439.5,279.017V222.231a1.894,1.894,0,0,1,1.889-1.894H525.05a1.894,1.894,0,0,1,1.889,1.894v56.826l-.082.035L484.681,297.15A2.051,2.051,0,0,1,483.883,297.311Z"
                    transform="translate(-405.5 -213.337)"
                    fill="#fff"
                  />
                  <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M483.519,297.082a2.173,2.173,0,0,1-.826-.162L439,278.743V221.866a2.029,2.029,0,0,1,2.024-2.029h83.661a2.029,2.029,0,0,1,2.024,2.029v56.915l-42.34,18.128a2.183,2.183,0,0,1-.851.172Zm-43.979-18.7L482.9,296.419a1.647,1.647,0,0,0,1.26-.007l42.011-17.987V221.866a1.488,1.488,0,0,0-1.484-1.488H441.024a1.488,1.488,0,0,0-1.484,1.488Z"
                    transform="translate(-405.449 -212.891)"
                    fill="#3f3d56"
                  />
                  <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M467.109,387.837h-.054L436.039,401.1,393.6,419.248a1.366,1.366,0,0,1-1.048.005l-43.782-18.191-31.778-13.2-.049-.022h-.054A1.9,1.9,0,0,0,315,389.731v82.254a1.9,1.9,0,0,0,1.891,1.894H467.109A1.9,1.9,0,0,0,469,471.985V389.731A1.9,1.9,0,0,0,467.109,387.837Zm1.351,84.148a1.352,1.352,0,0,1-1.351,1.353H316.891a1.352,1.352,0,0,1-1.351-1.353V389.731a1.355,1.355,0,0,1,1.3-1.353l31.932,13.269,43.574,18.107a1.921,1.921,0,0,0,1.47-.008l42.223-18.058,31.124-13.31a1.356,1.356,0,0,1,1.3,1.353Z"
                    transform="translate(-315 -335.88)"
                    fill="#3f3d56"
                  />
                  <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M531.46,286.487a5.841,5.841,0,0,1-3.514-1.167l-.063-.047-13.235-10.133A5.88,5.88,0,0,1,521.8,265.8l8.573,6.573,20.258-26.42a5.88,5.88,0,0,1,8.244-1.088l-.126.171.129-.169a5.887,5.887,0,0,1,1.088,8.244l-23.829,31.076a5.882,5.882,0,0,1-4.678,2.295Z"
                    transform="translate(-459.345 -219.656)"
                    fill="#02a8ee"
                  />
                </g>
              </svg>
            </div>
          </div>
        </SbizzlAnimate>
      </div>
    );
  }
}
export default Step3;
