// Initialize Firebase for the application
var config = {
  // apiKey: "AIzaSyBAL5PdGLdRr7IwIStvxX57SDYOiHC4byw",
  // authDomain: "localhost",
  // databaseURL: "https://sbizzl-app.firebaseio.com",
  // project_id: "sbizzl-app",
  // storageBucket: "sbizzl-app.appspot.com",
  // messagingSenderId: "175236807638"

  apiKey: "AIzaSyAVND1fOUkV7QJ85sqdqUbqLfur-ngphmo",
  authDomain: "sbizzl-app.firebaseapp.com",
  databaseURL: "https://sbizzl-app.firebaseio.com",
  projectId: "sbizzl-app",
  storageBucket: "sbizzl-app.appspot.com",
  messagingSenderId: "175236807638",
  appId: "1:175236807638:web:8a82295ddfcc8490"
};

export default config;
