import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Button,
  Typography,
  Grid,
  FormHelperText,
  Input,
  TextField
} from "@material-ui/core";
import config from "../config";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GooglePlacesAutocomplete from "../common/GooglePlacesAutocomplete";
import axios from "axios";
import SbizzlAnimate from "../common/SbizzlAnimate";

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loading: true,
      // dataSet: false,
      // countries: [],
      companyNameExistsMsg: "",
      companyNameExists: false
    };
  }

  // checkCompanyName = (values) => {
  //   if (values.companyName?.trim()) {
  //     this.setState({
  //       checkLoader: true
  //     });
  //     axios
  //       .get(
  //         `${
  //           config.api.gatewayService
  //         }/systemclient/clientName/${values.companyName.trim()}`,
  //         {}
  //       )
  //       .then((res) => {
  //         this.setState({
  //           checkLoader: false,
  //           companyNameExistsMsg: "",
  //           companyNameExists: false
  //         });
  //       })
  //       .catch((err) => {
  //         if (err.response.status === 406) {
  //           this.setState({
  //             companyNameExistsMsg: "Company name already available",
  //             companyNameExists: true,
  //             checkLoader: false
  //           });
  //         } else {
  //           this.setState({
  //             checkLoader: false,
  //             companyNameExistsMsg: "",
  //             companyNameExists: false
  //           });
  //         }
  //       });
  //   }
  // };

  handleGoogleAutoGeneretedAddress = (details, setValues, values) => {
    let addressDetail = {};
    if (details && details.address_components) {
      details.address_components.map((value) => {
        value.types.map((key) => {
          addressDetail[key] =
            key === "administrative_area_level_1"
              ? value?.short_name !== "" &&
                value?.short_name !== null &&
                value?.short_name !== undefined
                ? value.short_name
                : value.long_name
              : value.long_name;
          return key;
        });
        return value;
      });
    }

    setValues({
      ...values,
      addrLine1:
        (addressDetail.street_number ? addressDetail.street_number + " " : "") +
        (addressDetail.route ? addressDetail.route : ""),
      addrLine2: "",
      city: addressDetail.locality ? addressDetail.locality : "",
      state: addressDetail.administrative_area_level_1
        ? addressDetail.administrative_area_level_1
        : "",
      pincode: addressDetail.postal_code ? addressDetail.postal_code : "",
      country: addressDetail.country ? addressDetail.country : null
    });
  };

  // initializeScreen = () => {
  //   if (!this.state.dataSet) {
  //     axios
  //       .get(`${config.api.gatewayService}/systemclient/countries`, {})
  //       .then((res) => {
  //         this.setState(
  //           {
  //             countries: res.data.data
  //           },
  //           () => {
  //             this.setState({ dataSet: true });
  //           }
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  onSaveInfo = async (values) => {
    this.props.saveCompanyInfo(values);
    await this.props.goNextStep();
  };

  resendVerificationEmail = async () => {
    await axios
      .post(`${config.api.gatewayService}/resendSignupEmailVerification`, {
        email: this.props.userInfo.email
      })
      .then((res) => {
        this.props.goNextStep();
      })
      .catch((err) => {
        console.log("Error in resendSignupEmailVerification() : ", err);
      });
  };

  render() {
    // this.initializeScreen();
    const validateSchema = yup.object().shape({
      companyName: yup
        .string()
        .nullable()
        .trim()
        .min(1, "Please enter at least 1 character")
        .max(100, "Company name accept up to 100 characters")
        .required("Please enter company name"),
      addrLine1: yup
        .string()
        .nullable()
        .trim()
        .min(1, "Please enter at least 1 character")
        .max(100, "Street 1 accept up to 100 characters")
        .required("Please enter street 1"),
      addrLine2: yup
        .string()
        .nullable()
        .trim()
        .max(100, "Street 2 accept up to 100 characters"),
      city: yup
        .string()
        .nullable()
        .trim()
        .min(1, "Please enter at least 1 character")
        .max(70, "City accept up to 70 characters")
        .required("Please enter city"),
      state: yup
        .string()
        .nullable()
        .trim()
        .min(1, "Please enter at least 1 character")
        .max(65, "State accept up to 65 characters")
        .required("Please enter state"),
      pincode: yup
        .string()
        .nullable()
        .trim()
        .min(1, "Please enter at least 1 character")
        .max(10, "Zipcode accept up to 10 characters")
        .required("Please enter zipcode"),
      country: yup
        .string()
        .nullable()
        .trim()
        .min(1, "Please enter at least 1 character")
        .max(40, "Country accept up to 40 characters")
        .required("Please enter country"),
        phone: yup
        .string()
        .nullable()
        .trim()
        .max(20, "Contact number accept up to 20 characters"),
    });
    return (
      <Formik
        initialValues={this.props.companyInfo}
        enableReinitialize={true}
        validationSchema={validateSchema}
        onSubmit={(values) => {
          this.onSaveInfo(values);
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            setValues,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched
          } = props;

          return (
            <form className="ViewComponent" noValidate>
              <SbizzlAnimate animation="transition.slideRightIn" delay={250}>
                <div className="formWraper">
                  {!this.props.userInfo.unverifiedEmailExists ? (
                    <>
                      <div className="forTextStyle">
                        <Typography variant="h5" className="primaryColor">
                          Nice to meet you {this.props.userInfo.fName} !
                        </Typography>
                        <Typography variant="h3" className="secondaryColor">
                          Provide your company detail
                        </Typography>
                      </div>
                      <Grid item xs={12} sm={12}>
                        <div className="inputControl">
                          <Input
                            id="companyName"
                            error={errors.companyName && touched.companyName}
                            helperText={
                              errors.companyName &&
                              touched.companyName &&
                              errors.companyName
                            }
                            value={values.companyName}
                            onChange={handleChange}
                            // onBlur={this.checkCompanyName}
                            onBlur={() => {
                              this.setState({
                                companyNameExistsMsg: "",
                                companyNameExists: false
                              });
                              // this.checkCompanyName(values);
                              setFieldTouched("companyName", true);
                            }}
                            aria-describedby="companyName"
                            placeholder="Company name"
                            name="companyName"
                            type="text"
                            className="customInput w-full"
                            autoComplete="chrome-off"
                          />
                          {errors.companyName &&
                          touched.companyName &&
                          !this.state.companyNameExists ? (
                            <FormHelperText className="errorText">
                              {errors.companyName}
                            </FormHelperText>
                          ) : (
                            <FormHelperText className="errorText">
                              {this.state.companyNameExistsMsg}
                            </FormHelperText>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="inputControl googlePlaceAuto">
                          <GooglePlacesAutocomplete
                            placeholder="Street 1"
                            onSelect={(data) => {
                              this.handleGoogleAutoGeneretedAddress(
                                data,
                                setValues,
                                values
                              );
                            }}
                            onChange={(value) => {
                              setValues({
                                ...values,
                                addrLine1: value
                              });
                            }}
                            name="addrLine1"
                            lable="Street 1"
                            addrLineValue={values.addrLine1}
                            error={errors.addrLine1 && touched.addrLine1}
                            helperText={
                              errors.addrLine1 &&
                              touched.addrLine1 &&
                              errors.addrLine1
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="inputControl">
                          <Input
                            id="addrLine2"
                            error={errors.addrLine2 && touched.addrLine2}
                            helperText={
                              errors.addrLine2 &&
                              touched.addrLine2 &&
                              errors.addrLine2
                            }
                            value={values.addrLine2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-describedby="addrLine2"
                            placeholder="Street 2"
                            name="addrLine2"
                            type="text"
                            className="customInput"
                            autoComplete="chrome-off"
                          />
                          {errors.addrLine2 && touched.addrLine2 && (
                            <FormHelperText className="errorText">
                              {errors.addrLine2}
                            </FormHelperText>
                          )}
                        </div>
                      </Grid>
                      <Grid container spacing={2} className="formRowInput">
                        <Grid item xs={12} sm={6}>
                          <div className="inputControl">
                            <Input
                              id="city"
                              error={errors.city && touched.city}
                              helperText={
                                errors.city && touched.city && errors.city
                              }
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              aria-describedby="city"
                              placeholder="City"
                              name="city"
                              type="text"
                              className="customInput"
                              autoComplete="chrome-off"
                            />
                            {errors.city && touched.city && (
                              <FormHelperText className="errorText">
                                {errors.city}
                              </FormHelperText>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="inputControl">
                            <Input
                              id="state"
                              error={errors.state && touched.state}
                              helperText={
                                errors.state && touched.state && errors.state
                              }
                              value={values.state}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              aria-describedby="state"
                              placeholder="State"
                              name="state"
                              type="text"
                              className="customInput"
                              autoComplete="chrome-off"
                            />
                            {errors.state && touched.state && (
                              <FormHelperText className="errorText">
                                {errors.state}
                              </FormHelperText>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="formRowInput">
                        <Grid item xs={12} sm={6}>
                          <div className="inputControl">
                            <Input
                              id="pincode"
                              error={errors.pincode && touched.pincode}
                              helperText={
                                errors.pincode &&
                                touched.pincode &&
                                errors.pincode
                              }
                              value={values.pincode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              aria-describedby="pincode"
                              placeholder="Zipcode"
                              name="pincode"
                              type="text"
                              className="customInput"
                              autoComplete="chrome-off"
                            />
                            {errors.pincode && touched.pincode && (
                              <FormHelperText className="errorText">
                                {errors.pincode}
                              </FormHelperText>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} className="autocompleteStyle">
                          <div className="inputControl autocompleteErr">
                            <Autocomplete
                              autoFocus={false}
                              id="country"
                              size="small"
                              getOptionSelected={(option, value) => {
                                return (
                                  option.toLowerCase().trim() ===
                                  value.toLowerCase().trim()
                                );
                              }}
                              getOptionLabel={(i) => i}
                              options={
                                ["United States"]
                                // this.state.countries
                                //   ? this.state.countries.map((country) => {
                                //       return country.name;
                                //     })
                                //   : []
                              }
                              value={values.country}
                              onChange={(event, selectCountry) => {
                                setValues({
                                  ...values,
                                  country: selectCountry || null
                                });
                              }}
                              className="w-full autocompleteCountry"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={
                                    touched.country && errors.country
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors.country &&
                                    touched.country &&
                                    errors.country
                                  }
                                  autoFocus={false}
                                  placeholder="Country"
                                  variant="outlined"
                                  className="customInput w-full"
                                  name="country"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    )
                                  }}
                                />
                                // <div ref={params.InputProps.ref}>
                                //   <Input
                                //     {...params.inputProps}
                                //     error={errors.country && touched.country}
                                //     helperText={
                                //       errors.country &&
                                //       touched.country &&
                                //       errors.country
                                //     }
                                //     onBlur={handleBlur}
                                //     aria-describedby="country"
                                //     placeholder="Country"
                                //     name="country"
                                //     type="text"
                                //     className="customInput w-full"
                                //     autoComplete="chrome-off"
                                //   />
                                // </div>
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                    <div className="inputControl">
                      <Input
                        id="phone"
                        value={values.phone}
                        onChange={e =>{
                          setFieldValue("phone", e.target.value.replace(/[^0-9]/g, ""));
                        }}
                        onBlur={handleBlur}
                        aria-describedby="phone"
                        placeholder="Contact number"
                        name="phone"
                        type="text"
                        className="customInput"
                        autoComplete="chrome-off"
                      />
                    </div>
                  </Grid>

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="nextButton mt-0"
                        onClick={handleSubmit}
                        size="large"
                        disabled={
                          !values.companyName ||
                          !values.city ||
                          !values.state ||
                          !values.pincode ||
                          this.state.companyNameExists
                        }
                      >
                        Next
                      </Button>
                    </>
                  ) : (
                    <>
                      <div className="forTextStyle">
                        <Typography variant="h5" className="primaryColor">
                          Nice to meet you {this.props.userInfo.fName} !
                        </Typography>
                        <Typography variant="h3" className="secondaryColor">
                          You have a pending signup request. Please proceed to
                          finalize it!
                        </Typography>
                      </div>

                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        className="nextButton mt-0"
                        onClick={this.resendVerificationEmail}
                        size="large"
                      >
                        Resend
                      </Button>
                    </>
                  )}
                </div>
              </SbizzlAnimate>
              <SbizzlAnimate animation="transition.slideRightIn" delay={400}>
                <div className="imagBlockContainer">
                  <div className="imagBlock">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="290"
                      height="260"
                      viewBox="0 0 290 260"
                    >
                      <g
                        id="Group_65262"
                        data-name="Group 65262"
                        transform="translate(-886 -219.594)"
                      >
                        <path
                          id="blob"
                          d="M209.38,17.364c19.414,48.554-.991,116.455-46.356,145.847S47.531,183.3-3.183,148.7C-53.7,114.286-85,54.384-69.15,10.667-53.5-33.236,9.1-60.954,70.115-60.4,130.932-59.838,190.164-31,209.38,17.364Z"
                          transform="translate(959.289 289.998)"
                          fill="#02a8ee"
                          opacity="0.4"
                        />
                        <path
                          id="blob-2"
                          data-name="blob"
                          d="M-66.136,23.433c-18.946,52.343.967,125.543,45.238,157.229S91.81,202.321,141.3,165.019c49.3-37.1,79.843-101.677,64.377-148.806C190.406-31.116,129.315-61,69.771-60.4,10.421-59.794-47.383-28.709-66.136,23.433Z"
                          transform="translate(962.289 279.998)"
                          fill="#02a8ee"
                          opacity="0.1"
                        />
                        <g
                          id="undraw_growth_curve_re_t5s7"
                          transform="translate(886 252.594)"
                        >
                          <path
                            id="Path_168"
                            data-name="Path 168"
                            d="M469.307,628.443c1.063-8.548,6.361-16.97,14.513-19.752a39.687,39.687,0,0,0,0,27.248c1.253,3.388,3,7.027,1.82,10.442a8.872,8.872,0,0,1-4.531,4.767,26.352,26.352,0,0,1-6.427,1.976l-.432.358C470.763,645.6,468.244,636.99,469.307,628.443Z"
                            transform="translate(-366.307 -466.16)"
                            fill="#f0f0f0"
                          />
                          <path
                            id="Path_169"
                            data-name="Path 169"
                            d="M494.5,608.467a33.922,33.922,0,0,0-8.433,19.091,14.607,14.607,0,0,0,.166,4.574,8.378,8.378,0,0,0,2.084,3.886,17.344,17.344,0,0,1,2.691,3.221,5.13,5.13,0,0,1,.251,4.188,13.329,13.329,0,0,1-2.953,4.406,13.087,13.087,0,0,0-3.28,4.9c-.068.235-.428.115-.361-.119.978-3.372,4.251-5.287,5.812-8.325a5.2,5.2,0,0,0,.351-4.558,13.453,13.453,0,0,0-2.67-3.322,8.942,8.942,0,0,1-2.179-3.724,13.5,13.5,0,0,1-.341-4.551,32.908,32.908,0,0,1,2.4-10,34.523,34.523,0,0,1,6.208-9.948c.162-.182.412.1.251.285Z"
                            transform="translate(-376.951 -465.776)"
                            fill="#fff"
                          />
                          <path
                            id="Path_170"
                            data-name="Path 170"
                            d="M481.127,649.931a5.09,5.09,0,0,1-3.874-5.331c.019-.243.4-.225.379.019a4.713,4.713,0,0,0,3.614,4.952C481.484,649.627,481.363,649.988,481.127,649.931Z"
                            transform="translate(-371.819 -490.446)"
                            fill="#fff"
                          />
                          <path
                            id="Path_171"
                            data-name="Path 171"
                            d="M494.174,681.492a9.809,9.809,0,0,0,4.38-5.65c.069-.235.429-.115.361.119a10.2,10.2,0,0,1-4.57,5.869c-.211.125-.38-.215-.171-.339Z"
                            transform="translate(-383.378 -511.709)"
                            fill="#fff"
                          />
                          <path
                            id="Path_172"
                            data-name="Path 172"
                            d="M500.43,628.744a2.881,2.881,0,0,0,2.73-.139c.209-.127.378.212.171.339a3.229,3.229,0,0,1-3.02.16.2.2,0,0,1-.121-.24.191.191,0,0,1,.24-.121Z"
                            transform="translate(-387.553 -478.751)"
                            fill="#fff"
                          />
                          <path
                            id="Path_173"
                            data-name="Path 173"
                            d="M519.281,654.412c-.128.083-.256.167-.385.253a37.92,37.92,0,0,0-4.851,3.787c-.119.106-.237.215-.352.324a40,40,0,0,0-8.686,11.891,38.857,38.857,0,0,0-2.128,5.5c-.785,2.6-1.429,5.492-2.983,7.623a6.646,6.646,0,0,1-.519.641H485.333c-.032-.016-.064-.029-.1-.045l-.561.026c.023-.1.048-.2.071-.3.013-.058.029-.115.042-.173.009-.038.019-.077.026-.112,0-.013.006-.026.01-.035.006-.035.016-.067.023-.1q.211-.86.436-1.721s0,0,0-.006a57.1,57.1,0,0,1,4.806-12.618c.064-.119.128-.24.2-.359a37.057,37.057,0,0,1,3.329-5.05,32.758,32.758,0,0,1,2.182-2.5,27.244,27.244,0,0,1,6.818-5.107c5.037-2.659,10.868-3.678,16.251-2.054C519.008,654.326,519.143,654.367,519.281,654.412Z"
                            transform="translate(-377.183 -496.615)"
                            fill="#f0f0f0"
                          />
                          <path
                            id="Path_174"
                            data-name="Path 174"
                            d="M519.387,656.085a33.923,33.923,0,0,0-18.228,10.166A14.61,14.61,0,0,0,498.538,670a8.378,8.378,0,0,0-.676,4.357,17.343,17.343,0,0,1,.21,4.192,5.13,5.13,0,0,1-2.321,3.495,13.33,13.33,0,0,1-5.011,1.739,13.086,13.086,0,0,0-5.568,1.935c-.2.147-.412-.166-.216-.312,2.811-2.1,6.578-1.662,9.653-3.147a5.2,5.2,0,0,0,3.025-3.428,13.453,13.453,0,0,0-.132-4.26,8.941,8.941,0,0,1,.5-4.285,13.5,13.5,0,0,1,2.467-3.839,32.911,32.911,0,0,1,7.941-6.538,34.524,34.524,0,0,1,10.946-4.206c.239-.047.267.331.029.379Z"
                            transform="translate(-377.323 -498.109)"
                            fill="#fff"
                          />
                          <path
                            id="Path_175"
                            data-name="Path 175"
                            d="M537.817,668.979a5.089,5.089,0,0,1,.117-6.589c.162-.183.454.06.291.243a4.713,4.713,0,0,0-.1,6.13C538.284,668.951,537.971,669.166,537.817,668.979Z"
                            transform="translate(-412.566 -502.61)"
                            fill="#fff"
                          />
                          <path
                            id="Path_176"
                            data-name="Path 176"
                            d="M524.442,707.708a9.81,9.81,0,0,0,6.9-1.874c.2-.146.412.167.216.312a10.2,10.2,0,0,1-7.183,1.935C524.131,708.055,524.2,707.681,524.442,707.708Z"
                            transform="translate(-404.081 -532.153)"
                            fill="#fff"
                          />
                          <path
                            id="Path_177"
                            data-name="Path 177"
                            d="M568.353,663.934a2.881,2.881,0,0,0,2.263,1.533c.244.024.174.4-.068.374a3.228,3.228,0,0,1-2.508-1.69.2.2,0,0,1,.048-.264.191.191,0,0,1,.264.048Z"
                            transform="translate(-434.081 -503.648)"
                            fill="#fff"
                          />
                          <path
                            id="Path_178"
                            data-name="Path 178"
                            d="M612.812,156.028a2.245,2.245,0,0,0-2.243,2.243v94.842a2.245,2.245,0,0,0,2.243,2.243H751.871a2.245,2.245,0,0,0,2.243-2.243V158.271a2.245,2.245,0,0,0-2.243-2.243Z"
                            transform="translate(-466.114 -153.904)"
                            fill="#e6e6e6"
                          />
                          <path
                            id="Path_179"
                            data-name="Path 179"
                            d="M622.812,166.028a2.245,2.245,0,0,0-2.243,2.243V256.7a2.245,2.245,0,0,0,2.243,2.243H755.463a2.246,2.246,0,0,0,2.243-2.243V168.271a2.245,2.245,0,0,0-2.243-2.243Z"
                            transform="translate(-472.91 -160.7)"
                            fill="#fff"
                          />
                          <path
                            id="Path_180"
                            data-name="Path 180"
                            d="M769.061,225.815c-3.05-1.191-6.34,1.8-7.054,4.992s.2,6.5.4,9.769-.655,7.064-3.571,8.554c-3.353,1.713-7.287-.514-11.037-.853a11.106,11.106,0,0,0-9.78,4.431c-3.093,4.243-2.936,10.4-6.6,14.17-3.18,3.271-8.362,3.7-12.811,2.686s-8.538-3.209-12.912-4.5-9.438-1.566-13.186,1.034c-3.913,2.715-5.655,8.036-9.951,10.092-3.038,1.454-6.633.9-9.884.019s-6.529-2.068-9.884-1.785-6.854,2.6-7.12,5.955l115.176-.322q.785-22.8,1.57-45.607C772.534,231.142,772.14,227.017,769.061,225.815Z"
                            transform="translate(-496.767 -201.148)"
                            fill="#6c63ff"
                            opacity="0.1"
                          />
                          <path
                            id="Path_181"
                            data-name="Path 181"
                            d="M471.049,441.173a3.866,3.866,0,0,1,2.9-5.172l2.023-13.59,5.317,4.766-2.736,12.175a3.887,3.887,0,0,1-7.5,1.821Z"
                            transform="translate(-367.448 -337.3)"
                            fill="#ffb6b6"
                          />
                          <path
                            id="Path_182"
                            data-name="Path 182"
                            d="M340.983,446.294a3.866,3.866,0,0,0-1.274-5.79l2.007-13.592-6.471,3.018-.914,12.445a3.887,3.887,0,0,0,6.651,3.92Z"
                            transform="translate(-273.565 -340.392)"
                            fill="#ffb6b6"
                          />
                          <path
                            id="Path_183"
                            data-name="Path 183"
                            d="M303.125,532.8h6l2.856-23.157h-8.861Z"
                            transform="translate(-207.997 -350.978)"
                            fill="#ffb6b6"
                          />
                          <path
                            id="Path_184"
                            data-name="Path 184"
                            d="M439.779,725.2H451.6a7.535,7.535,0,0,1,7.535,7.534v.245H439.779Z"
                            transform="translate(-346.226 -545.335)"
                            fill="#2f2e41"
                          />
                          <path
                            id="Path_185"
                            data-name="Path 185"
                            d="M203.125,532.8h6l2.856-23.157h-8.861Z"
                            transform="translate(-139.379 -350.978)"
                            fill="#ffb6b6"
                          />
                          <path
                            id="Path_186"
                            data-name="Path 186"
                            d="M339.779,725.2H351.6a7.535,7.535,0,0,1,7.535,7.534v.245H339.779Z"
                            transform="translate(-277.583 -545.335)"
                            fill="#2f2e41"
                          />
                          <circle
                            id="Ellipse_56"
                            data-name="Ellipse 56"
                            cx="10.845"
                            cy="10.845"
                            r="10.845"
                            transform="translate(80.716 6.693)"
                            fill="#ffb6b6"
                          />
                          <path
                            id="Path_187"
                            data-name="Path 187"
                            d="M343.834,445.694s.16,48.531-.615,49.306-3.525,29.8-3.525,29.8h9.612c.641,0,16.992-63.224,16.992-63.224s6.972,34.085,5.81,36.022-.256,27.325.387,29.437c0,0,8.077,2.585,8.852.648s1.922-28.2,1.922-28.2l2.243-43.576.049-11.377Z"
                            transform="translate(-277.67 -354.6)"
                            fill="#2f2e41"
                          />
                          <path
                            id="Path_188"
                            data-name="Path 188"
                            d="M485.522,274.084h4.315a1.4,1.4,0,0,1,1.363,1.089c.666,2.949,2.382,11.307,2.068,17.89-.387,8.134-1.162,20.916-1.162,22.078s-3.873,18.979-3.873,18.979-2.663-4.377-5.592-3.544l.557-15.823.775-6.585-1.162-11.62Z"
                            transform="translate(-375.577 -235.529)"
                            fill="#e4e4e4"
                          />
                          <path
                            id="Path_189"
                            data-name="Path 189"
                            d="M371.438,251.951s-13.75,4.574-15.3,8.447,5.973,31.953,5.973,31.953-2.884,12.817-6.747,24.985c-1.758,5.537,15.021.8,22.768-1.915,4.8-1.665,13.679.892,18.676-.022,0,0,.775-10.458,0-12.395s-2.711-.775-1.549-3.486.775-3.1.387-4.261.775-2.711.775-4.261-.387-5.035-.387-5.035,10.458-24.4,8.134-26.726-14.154-6.8-14.154-6.8S390.307,252.62,371.438,251.951Z"
                            transform="translate(-288.372 -220.313)"
                            fill="#e4e4e4"
                          />
                          <path
                            id="Path_190"
                            data-name="Path 190"
                            d="M344.551,274.084l-1.376.688a3.879,3.879,0,0,0-2.078,2.755l-5.455,29.093-1.162,27.5s6.585-1.937,8.521,0c0,0-1.937-5.81-.775-7.747a10.459,10.459,0,0,0,1.162-4.648s-.775,0,1.162-1.937,2.324-2.711,1.937-3.1-.775-3.873-.775-4.261,3.873-26.339,3.873-26.339Z"
                            transform="translate(-273.926 -235.529)"
                            fill="#e4e4e4"
                          />
                          <path
                            id="Path_191"
                            data-name="Path 191"
                            d="M652.6,276.558a.641.641,0,0,1,0-1.282c29.611,0,57.851-5.929,79.517-16.695,20.316-10.1,33.263-23.785,35.52-37.559a.641.641,0,0,1,1.265.205v0c-1.172,7.155-4.977,14.174-11.309,20.861-6.245,6.6-14.624,12.53-24.9,17.638C710.843,270.582,682.4,276.558,652.6,276.558Z"
                            transform="translate(-494.239 -197.708)"
                            fill="#02a8ee"
                          />
                          <path
                            id="Path_192"
                            data-name="Path 192"
                            d="M632.536,118.236h-122.4V57.765h.641v59.83H632.536Z"
                            transform="translate(-352.546 -39.256)"
                            fill="#e6e6e6"
                          />
                          <path
                            id="Path_193"
                            data-name="Path 193"
                            d="M395.344,170.591a5.959,5.959,0,0,1-.227-3.83c.886,2.35,2.646,1.632,3.543.531,1.125-1.382.8-4.573,2.577-4.716a19.9,19.9,0,0,1,5.3.528l-.87-1.489,3.8.236-1.131-1.86a12.983,12.983,0,0,1,7.689,7.276,8.326,8.326,0,0,0-4.374-12.25l1.5-1.965-10.164-1a3.018,3.018,0,0,1,1.183-2.555,5.085,5.085,0,0,0-3.874.914,15.087,15.087,0,0,0-2.915,2.855,23.26,23.26,0,0,0-3.571,5.041A11.875,11.875,0,0,0,395.344,170.591Zm.123-4.773h0l.01,0Z"
                            transform="translate(-313.98 -149.399)"
                            fill="#2f2e41"
                          />
                          <path
                            id="Path_194"
                            data-name="Path 194"
                            d="M349.214,748.983h-207.4a.381.381,0,1,1,0-.763h207.4a.381.381,0,1,1,0,.763Z"
                            transform="translate(-141.431 -560.983)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </svg>
                    <Typography variant="h5">
                      {" "}
                      Give a boost to your business{" "}
                    </Typography>
                  </div>
                </div>
              </SbizzlAnimate>
            </form>
          );
        }}
      </Formik>
    );
  }
}
export default Step2;
