module.exports = {
  // api: {
  //   graphqlService: "http://graphql-service-cluster-ip-service",
  //   iamService: "http://iam-service-cluster-ip-service",
  //   saService: "http://super-admin-service-cluster-ip-service"
  // }
  api: {
    // graphqlService: "http://35.238.23.166/graphiql",
    // graphql: "http://35.238.23.166/graphiql",
    // iamService: "http://35.239.48.59",
    // saService: "http://35.238.65.239",
    // hrService: "http://35.222.189.230",

    // graphqlService: "https://graphqlservice.sbizzl.com/graphiql",
    // graphql: "https://graphqlservice.sbizzl.com/graphiql",
    // iamService: "https://iam.sbizzl.com",
    // saService: "https://saservice.sbizzl.com",
    // hrService: "https://hr.sbizzl.com",

    graphqlService: "https://app.sbizzl.com/graphql",
    graphqlWs: "wss://app.sbizzl.com/graphql",
    // iamService: "https://app.sbizzl.com/iam",
    // saService: "https://app.sbizzl.com/sa",
    // hrService: "https://app.sbizzl.com/hr",
    gatewayService: "https://app.sbizzl.com/gtw"
    
  },
  urls: {
    signin: "https://sbizzl.com/"
  }
};
//exports prod files
