import React, { useState, useEffect, useCallback } from "react";
import { Typography, Link } from "@material-ui/core";
import axios from "axios";
import { withToastManager } from "react-toast-notifications";
import withFirebaseAuth from "react-with-firebase-auth";
import * as firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "../firebaseConfig";
import SbizzlAnimate from "../common/SbizzlAnimate";
import config from "../config";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import LogoPath from "../assets/img/logo-lg.png";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { useParams } from "react-router-dom";
//----------------------------Sign up with google-----------------------------------
const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
};
//----------------------------------------------------------------------------------

const Stepper = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [deviceData, setDeviceData] = useState({});
  const [progress, setProgress] = useState(0);
  const [info, setInfo] = useState({
    isAgree: true,
    fName: "",
    lName: "",
    email: "",
    emailVerified: false,
    signupWithGoogle: false,
    unverifiedEmailExists: false
  });
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    addrLine1: "",
    addrLine2: "",
    city: "",
    state: "",
    pincode: "",
    country: "United States",
    phone: "",
  });
  const { uuid } = useParams();
  useEffect(() => {
    var deviceData = {
      timeOpened: new Date(),
      timezone: new Date().getTimezoneOffset() / 60,
      pageon: window.location.pathname,
      referrer: document.referrer,
      previousSites: window.history.length,
      browserName: navigator.appName,
      browserEngine: navigator.product,
      browserVersion1a: navigator.appVersion,
      browserVersion1b: navigator.userAgent,
      browserLanguage: navigator.language,
      browserOnline: navigator.onLine,
      browserPlatform: navigator.platform,
      javaEnabled: navigator.javaEnabled(),
      dataCookiesEnabled: navigator.cookieEnabled,
      dataCookies1: document.cookie,
      dataCookies2: decodeURIComponent(document.cookie.split(";")),
      sizeScreenW: window.screen.width,
      sizeScreenH: window.screen.height,
      sizeAvailW: window.screen.availWidth,
      sizeAvailH: window.screen.availHeight,
      scrColorDepth: window.screen.colorDepth,
      scrPixelDepth: window.screen.pixelDepth,
      width: window.innerWidth,
      height: window.innerHeight
    };
    navigator.geolocation.getCurrentPosition((position) => {
      setDeviceData({
        ...deviceData,
        accuracy: position.coords.accuracy,
        altitude: position.coords.altitude,
        altitudeAccuracy: position.coords.altitudeAccuracy,
        heading: position.coords.heading,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        speed: position.coords.speed,
        timestamp: position.timestamp
      });
    });
    setDeviceData({ ...deviceData, ...deviceData });

    if (uuid) {
      axios
        .get(`${config.api.gatewayService}/planPriceList/uuid/${uuid}`, {})
        .then((res) => {
          if (!res.data.data?.id) {
            window.open(config.urls.signin, "_self");
          }
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    setProgress(currentStep === 2 ? 66.66 : (100 / 3) * currentStep);
  }, [currentStep]);

  const goNextStep = () => {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  };

  const saveInfo = (values) => {
    setInfo(values);
  };
  const saveCompanyInfo = async (values) => {
    setCompanyInfo(values);
    onSignupFormSubmit(values);
  };
  const goPreviousStep = () => {
    const previousStep = currentStep - 1;
    setCurrentStep(previousStep);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = useCallback((e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    props.signOut();
    return message;
  }, []);

  const onSignupFormSubmit = async (items) => {
    let values = { ...info, ...items };
    let systemClient = {};
    let owner = {};
    await window.removeEventListener("beforeunload", handleBeforeUnload);

    owner.fName =
      typeof values.fName == "string" ? values.fName.trim() : values.fName;
    owner.mName = "";
    owner.lName =
      typeof values.lName == "string" ? values.lName.trim() : values.lName;
    owner.email =
      typeof values.email == "string" ? values.email.trim() : values.email;
      owner.phone =
      typeof values.phone == "string" ? values.phone.trim() : values.phone;
    owner.password = "";
    systemClient.isVerified = values.emailVerified;
    systemClient.signupWithGoogle = values.signupWithGoogle;
    systemClient.clientName =
      typeof values.companyName == "string"
        ? values.companyName.trim()
        : values.companyName;
    systemClient.industryId = 1;
    systemClient.addrLine1 =
      typeof values.addrLine1 == "string"
        ? values.addrLine1.trim()
        : values.addrLine1;
    systemClient.addrLine2 =
      typeof values.addrLine2 == "string"
        ? values.addrLine2.trim()
        : values.addrLine2;
    systemClient.city =
      typeof values.city == "string" ? values.city.trim() : values.city;
    systemClient.state =
      typeof values.state == "string" ? values.state.trim() : values.state;
    systemClient.pincode =
      typeof values.pincode == "string"
        ? values.pincode.trim()
        : values.pincode;
    systemClient.country =
      typeof values.country == "string"
        ? values.country.trim()
        : values.country;
    systemClient.owner = owner;

    systemClient.deviceData = JSON.stringify(deviceData);
    systemClient.uuid = uuid || "";

    await axios
      .post(`${config.api.gatewayService}/systemclient/signup`, {
        systemClient
      })
      .then((res) => {
        if (systemClient.signupWithGoogle) {
          window.location.href = res.data.data.signInLink;
        }

        try {
          // if(process.env.NODE_ENV === "production"){
          window.gtag('event', 'conversion', {'send_to': 'AW-16525714151/p9v_CIvz9qYZEOfFicg9'});
          // }
        } catch (err) {
          console.log("204:--fail to recored conversion-- ");
        }
      })
      .catch((err) => {
        if (err?.response?.status == 406) {
          setCurrentStep(0);
          window.open(config.urls.signin, "_self");
        }
      });
  };

  return (
    <div className="ViewContainer">
      <SbizzlAnimate animation="transition.fadeIn" delay={200}>
        <>
          {currentStep > 0 && (
            <>
              <span className="bgProgress" />
              <div
                className="stepper-progress-bar"
                style={{ width: progress + "%" }}
              />
            </>
          )}
        </>
      </SbizzlAnimate>
      <SbizzlAnimate animation="transition.slideRightIn" delay={200}>
        <div className="header">
          <div>
            <img src={LogoPath} alt="logo" />
            {currentStep === 1 && (
              <p className="backButton" onClick={goPreviousStep}>
                <ArrowBackIos /> back
              </p>
            )}
          </div>
          {currentStep === 0 && (
            <Typography variant="p" Component="p">
              Have an account?{" "}
              <Link href="https://app.sbizzl.com/login" target="_blank">
                Sign in.
              </Link>
            </Typography>
          )}
        </div>
      </SbizzlAnimate>
      {currentStep === 0 && (
        <Step1
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          saveInfo={saveInfo}
          info={info}
          user={props.user}
          signOut={props.signOut}
          signInWithGoogle={props.signInWithGoogle}
        />
      )}
      {currentStep === 1 && (
        <Step2
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          saveCompanyInfo={saveCompanyInfo}
          companyInfo={companyInfo}
          user={props.user}
          userInfo={info}
        />
      )}
      {currentStep === 2 && !info.signupWithGoogle && (
        <Step3
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          user={props.user}
          email={info.email}
        />
      )}
      {currentStep === 2 && info.signupWithGoogle && (
        <Step4
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          user={props.user}
          signupWithGoogle={info.signupWithGoogle}
        />
      )}
      {currentStep === 3 && (
        <Step5
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          saveInfo={saveInfo}
          info={info}
          user={props.user}
          signOut={props.signOut}
          signInWithGoogle={props.signInWithGoogle}
        />
      )}
    </div>
  );
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth
})(withToastManager(Stepper));
