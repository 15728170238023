import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import CreatePassword from "./Stepper/Step5";
import SystemClientSignup from "./Stepper/Step4";

class AppRoot extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/createPassword" exact component={CreatePassword} />
          <Route
            path="/systemClientSignup"
            exact
            component={SystemClientSignup}
          />
          <Route path="/:uuid" exact component={App} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default AppRoot;
