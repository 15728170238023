import React from "react";
import Stepper from "./Stepper/Stepper";

const App = () => {
  return (
      <Stepper />
  );
}

export default App
