module.exports = {
  api: {
    port: 8080,
    bodyLimit: "100kb",
    corsHeaders: ["Link"],
    // graphqlService: "https://uatgraphqlservice.sbizzl.com",
    // graphqlWs: "wss://uatgraphqlservice.sbizzl.com/graphql",
    // iamService: "https://uatiamservice.sbizzl.com",
    // saService: "https://uatsaservice.sbizzl.com",
    // hrService: "https://uathrservice.sbizzl.com"
    graphqlService: "https://uat.sbizzl.com/graphql",
    graphqlWs: "wss://uat.sbizzl.com/graphql",
    // iamService: "https://uat.sbizzl.com/iam",
    // saService: "https://uat.sbizzl.com/sa",
    // hrService: "https://uat.sbizzl.com/hr",
    gatewayService: "https://uat.sbizzl.com/gtw",
  },
  urls: {
    signin: "https://sbizzl.com/",
  },
};
